import {Component} from '@angular/core';
import {RouterLink} from '@angular/router';
import {LengthAwarePaginator} from '../../shared/models/LengthAwarePaginator';
import {CommonModule} from '@angular/common';
import {DateTimeFormatPipe} from 'src/app/shared/pipes/date-time-format.pipe';
import {NgbPagination, NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {AlertService} from 'src/app/customer/customer-detail/customer-alerts/services/alert.service';
import {SmsAlert} from 'src/app/customer/models/sms-alert.model';


@Component({
  selector: 'app-sms-alerts',
  templateUrl: './sms-alerts.component.html',
  styleUrls: ['./sms-alerts.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    DateTimeFormatPipe,
    RouterLink,
    NgbPagination,
    NgbTooltip,
  ],
})
export class SmsAlertsComponent {

  alerts: LengthAwarePaginator<SmsAlert>;

  constructor(
    private alertService: AlertService,
  ) {
    this.fetchSmsAlerts(1)
  }

  onPageChange(page: number) {
    this.fetchSmsAlerts(page);
  }

  private fetchSmsAlerts(page: number): void {
    this.alertService
      .get({page: page, inquiry_type: 'sms', status: 'open'})
      .subscribe(smsAlerts => {
        // @ts-expect-error need type guard
        this.alerts = smsAlerts;
      });
  }
}
