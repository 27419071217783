import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {ClearState} from 'src/app/shared/state/session.actions';
import {Customer} from 'src/app/customer/models/customer.model';
import {
  ApproveRentDeclarations, CreateNote,
  FlagRentTransactions, ResolveSmsAlert,
  UpdateAlerts,
  UpdateBankTransactions,
  UpdateCustomer,
  UpdateNotes,
  UpdateRentInfo,
  UpdateSmsAlerts,
} from 'src/app/shared/state/customer.actions';
import {SmsAlert} from 'src/app/customer/models/sms-alert.model';
import {Alert} from 'src/app/customer/models/alert.model';
import {LengthAwarePaginator} from 'src/app/shared/models/LengthAwarePaginator';
import {RetrievedNote} from 'src/app/notes/models/retrieved-note.model';
import {DeclarationApproval, RentInfoResponse, RentReportingInfo} from 'src/app/rent-reporting/models/rent-declaration-info';
import {ObservableInput, of, switchMap} from 'rxjs';
import {CustomerService} from 'src/app/customer/services/customer.service';
import {tap} from 'rxjs/operators';
import {NoteService} from 'src/app/notes/services/note.service';
import {BudTransaction} from 'src/app/rent-reporting/models/bud-transaction';
import {RentReportingBatch} from 'src/app/rent-reporting/models/rent-reporting-batch';
import {AlertService} from 'src/app/customer/customer-detail/customer-alerts/services/alert.service';
import {patch, updateItem} from '@ngxs/store/operators';

/**
 * The customer state is designed to support the customer detail page.  The objectives are:
 * - Holds one customer at a time, including all sections of that detail page.
 * - If any section fetches data for a different GUID, refetch `customer` property to the new GUID and blow out all other data
 * - If the customer detail page of the same customer is viewed twice in a row, no API calls should be made.
 * - Actions defined here are meant to be called from the app, not services like customer.service.  The action, if necessary, will call
 *   to the service to fetch data.  This way the state is managed by the state.
 */

export interface CustomerStateModel {
  // Currently loaded customer
  guid: string;
  customer: Customer | null;

  // Supporting sections
  alerts: LengthAwarePaginator<Alert>;
  smsAlerts: LengthAwarePaginator<SmsAlert>;
  notes: LengthAwarePaginator<RetrievedNote>,
  rentInfo: RentReportingInfo | null,
  declarationApproval: DeclarationApproval | null,
  bankTransactions: LengthAwarePaginator<BudTransaction>,
  rentBatches: RentReportingBatch[];
  recentLogins: [],
  mailEvents: [],
}


const stateDefaults: CustomerStateModel = {
  guid: '',
  customer: null,

  alerts: null,
  smsAlerts: null,
  notes: null,
  rentInfo: null,
  declarationApproval: null,
  bankTransactions: null,
  rentBatches: [],
  recentLogins: [],
  mailEvents: [],
};


@State<CustomerStateModel>({
  name: 'customer',
  defaults: stateDefaults
})


@Injectable()
export class CustomerState {

  constructor(
    private customerService: CustomerService,
    private notesService: NoteService,
    private alertService: AlertService,
  ) {
  }

  @Selector()
  static getCustomer(state: CustomerStateModel) {
    if (state.customer) {
      return new Customer(state.customer);
    } else {
      return null;
    }
  }

  @Selector()
  static getNotes(state: CustomerStateModel) {
    return state.notes;
  }

  @Selector()
  static getAlerts(state: CustomerStateModel) {
    return state.alerts;
  }

  @Selector()
  static getSmsAlerts(state: CustomerStateModel) {
    return state.smsAlerts;
  }

  @Selector()
  static getRentInfo(state: CustomerStateModel) {
    return state.rentInfo;
  }

  @Selector()
  static getDeclarationApproval(state: CustomerStateModel) {
    return state.declarationApproval;
  }

  @Selector()
  static getBankTransactions(state: CustomerStateModel) {
    return state.bankTransactions;
  }

  @Selector()
  static getRentBatches(state: CustomerStateModel) {
    return state.rentBatches;
  }

  @Action(UpdateCustomer)
  updateCustomer(ctx: StateContext<CustomerStateModel>, action: UpdateCustomer) {
    // If this is a different customer than is in the state currently
    const customerInState = ctx.getState().guid === action.guid || action.guid == '';

    // If we are setting a new customer, empty state.
    if (!customerInState) {
      const newState = {...stateDefaults};

      // Put new GUID in state.  This will prevent successive UpdateCustomer actions on the same customer from doing mutiple API calls
      newState.guid = action.guid;
      ctx.setState(newState);
    }

    // Need to check for !ctx.getState().customer as well.  If we have two calls (resolvers) hitting this action at the same time,
    // we want both to call to customerService.fetch().  That service will return the same observable, and both resolvers will properly
    // wait for the call to finish.
    if (!customerInState || action.force || !ctx.getState().customer) {
      // Perform API operation
      return this.customerService.fetch(action.guid).pipe(
        // Store result in state.
        tap(customer => ctx.patchState({customer: customer}))
      );
    } else {
      // Since we already have the customer, skip the fetch operation.
      return of(ctx.getState().customer);
    }
  }

  @Action(UpdateNotes)
  updateNotes(ctx: StateContext<CustomerStateModel>, action: UpdateNotes) {
    return this.ensureCustomer(ctx, action.guid, () => {
      const notes = ctx.getState().notes;

      // If there are no notes OR they don't match the pagination parameters
      if (
        action.force
        || notes == null
        // || action.perPage != notes.per_page
        || (action.page != notes.current_page && action.page > 0)
      ) {
        return this.notesService.fetchByGuid(ctx.getState().guid, action.page).pipe(
          tap(notes => ctx.patchState({notes: notes})),
        )
      } else {
        return of(ctx.getState().notes);
      }
    });
  }

  @Action(CreateNote)
  createNote(ctx: StateContext<CustomerStateModel>, action: CreateNote) {
    let obs = this.notesService
      .create(action.note, action.isResolveNote);

    const customerInState = ctx.getState().guid === action.note.customer_guid;

    // If we added a note for the customer in the state, fetch new notes.
    if (customerInState) {
      obs = obs.pipe(
        tap(() => ctx.dispatch(new UpdateNotes(action.note.customer_guid, 0, true))),
      )
    }

    return obs;
  }

  @Action(UpdateAlerts)
  updateAlerts(ctx: StateContext<CustomerStateModel>, action: UpdateAlerts) {
    ctx.patchState({alerts: action.alerts});
  }

  @Action(UpdateSmsAlerts)
  updateSmsAlerts(ctx: StateContext<CustomerStateModel>, action: UpdateSmsAlerts) {
    return this.ensureCustomer(ctx, action.guid, () => {
      const smsAlerts = ctx.getState().smsAlerts;

      // If there are no alerts OR they don't match the pagination parameters
      if (
        action.force
        || smsAlerts == null
        || (action.page != smsAlerts.current_page && action.page > 0)
      ) {
        return this.alertService.fetchSmsByGuid(ctx.getState().guid, action.page).pipe(
          tap(alerts => ctx.patchState({smsAlerts: alerts})),
        )
      } else {
        return of(ctx.getState().smsAlerts);
      }
    });
  }

  @Action(ResolveSmsAlert)
  resolveSmsAlert(ctx: StateContext<CustomerStateModel>, action: ResolveSmsAlert) {
    return this.alertService
      .resolveSmsAlert(action.alertId)
      .pipe(
        tap(alert => {
          ctx.setState(
            patch({
              smsAlerts: patch({
                data: updateItem<SmsAlert>(
                  item => item.id == action.alertId,
                  alert,
                ),
              }),
            }),
          )
        }),
      );
  }

  @Action(UpdateRentInfo)
  updateRentInfo(ctx: StateContext<CustomerStateModel>, action: UpdateRentInfo) {
    return this.ensureCustomer(ctx, action.guid, () => {
      const rent = ctx.getState().rentInfo;

      // If there are no notes OR they don't match the pagination parameters
      if (action.force || rent == null) {
        return this.customerService.fetchRentInfo(ctx.getState().customer.uid).pipe(
          tap((rentInfo: RentInfoResponse) => ctx.patchState({
            rentInfo: rentInfo.rent_info,
            rentBatches: rentInfo.batches,
            declarationApproval: rentInfo.approval,
          })),
        )
      } else {
        return of(ctx.getState().rentInfo);
      }
    });
  }

  @Action(UpdateBankTransactions)
  updateBankTransactions(ctx: StateContext<CustomerStateModel>, action: UpdateBankTransactions) {
    return this.ensureCustomer(ctx, action.guid, () => {
      const transactions = ctx.getState().bankTransactions;

      // If there are no notes OR they don't match the pagination parameters
      if (
        action.force
        || transactions == null
        || (action.page != transactions.current_page && action.page > 0)
      ) {
        return this.customerService.fetchBankTransactions(ctx.getState().customer.uid, action.page).pipe(
          tap(rentInfo => ctx.patchState({bankTransactions: rentInfo})),
        )
      } else {
        return of(ctx.getState().bankTransactions);
      }
    });
  }

  @Action(FlagRentTransactions)
  flagRentTransactions(ctx: StateContext<CustomerStateModel>, action: FlagRentTransactions) {
    // The transactions must belong to the customer in the state.
    const customer = ctx.getState().customer;

    // Ensure all transactions are for the current customer.  If not, something unexpected happened. We shouldn't proceed.
    if (!action.transactions.every(t => t.uid === customer.uid)) {
      throw Error('Flagged rent transactions do not match customer in state');
    }

    // Create an array of only the ID properties
    const ids = action.transactions.map(t => t.id);

    return this.customerService
      .flagRentTransactions(customer.uid, ids)
      .pipe(
        // Update state with new transaction data.  Don't wait for response; components will be subscribed to getBankTransactions
        // for updates.
        tap(() => ctx.dispatch(new UpdateBankTransactions(customer.guid, 0, true)))
      );
  }

  @Action(ApproveRentDeclarations)
  appoveRentDeclarations(ctx: StateContext<CustomerStateModel>, action: ApproveRentDeclarations) {
    return this.customerService
      .approveRentDeclaration(action.guid, action.rentInfoId)
      .pipe(
        // Update state with new data.  Don't wait for response; components will be subscribed to getBankTransactions
        // for updates.
        tap(() => ctx.dispatch(new UpdateRentInfo(action.guid, true)))
      );
  }

  @Action(ClearState)
  clearState(ctx: StateContext<CustomerStateModel>) {
    ctx.setState(stateDefaults);
  }

  protected ensureCustomer(ctx: StateContext<CustomerStateModel>, guid: string, callback: (value: void, index: number) => ObservableInput<any>) {
    return ctx
      // Ensure we are on the action specified customer. If not, this will switch to them
      .dispatch(new UpdateCustomer(guid))
      .pipe(
        // Switch to actual operation
        switchMap(callback),
      );
  }
}
