import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CustomerLiteInterface} from '../models/customer-lite.interface';
import {HttpErrorResponse} from '@angular/common/http';
import {DineCardService} from '../services/dine-card.service';
import {AlertService} from "@ratespecial/core";
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

@Component({
  selector: 'app-dine-card-enroll-modal',
  templateUrl: './dine-card-enroll-modal.component.html',
  styleUrls: ['./dine-card-enroll-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule],
})
export class DineCardEnrollModalComponent {

  public customer: CustomerLiteInterface;
  /**
   * If this is injected, there will be a circular dependency (dine service -> modal -> dine service).  So pass
   * the instance in from the calling service.
   */
  public dineService: DineCardService;

  public errors = [];
  public objectKeys = Object.keys;
  public selectedDuration: number;

  constructor(public activeModal: NgbActiveModal,
              private alertService: AlertService) { }

  submit(): void {
    this.errors = [];

    if (!this.selectedDuration) {
      this.errors.push('Select a duration');
      return;
    }

    this.dineService
      .subscribeToDineCard(this.customer.guid, this.selectedDuration)
      .subscribe({
        next: () => {
          this.alertService.success('Subscribed to Dine');
          this.close();
        },
        error: (err: HttpErrorResponse) => {
          this.handleValidationErrors(err);
        }
      });
  }

  private handleValidationErrors(err) {
    if (err.status == 422) {
      err.error.errors.map(error => {
        this.alertService.addApiError(error);
      });
    }

    this.close();
  }

  close(): void {
    this.activeModal.close();
  }
}
