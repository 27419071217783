import {Component, OnDestroy} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {NgbActiveModal, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {CustomerService} from '../services/customer.service';
import {DateUtils} from 'src/app/shared/utils/date-utils';
import {ErrorService} from 'src/app/shared/error/error.service';
import {UserService} from 'src/app/shared/auth/services/user.service';
import {AlertService} from "@ratespecial/core";
import {Subscription} from 'rxjs';
import PCAAddress from '../../shared/services/pca/PCAAddress';
import AddressFormatter from '../../shared/services/pca/AddressFormatter';
import PCAService from '../../shared/services/pca/PCAService';
import {Customer} from 'src/app/customer/models/customer.model';

@Component({
  selector: 'app-customer-edit-modal',
  templateUrl: './customer-edit-modal.component.html',
  styleUrls: ['./customer-edit-modal.component.css']
})
export class CustomerEditModalComponent implements OnDestroy {

  public minDate: NgbDateStruct = {year: 1910, month: 1, day: 1};

  /**
   * Form Model
   * @type {{guid: string; fname: string; lname: string; phone: string; dob: {}; straddr: string; building_name: string; city: string; zip: string; third_party_marketing: string; forwardAddress: boolean}}
   */
  public customerModel = {
    guid: '',
    fname: '',
    lname: '',
    email: '',
    phone: '',
    dob: null,
    abodenumber: '',
    straddr: '',
    street: '',
    building_name: '',
    city: '',
    zip: '',
    third_party_marketing: '',
    forwardAddress: true,
  };

  public dob: NgbDateStruct = {
    day: 0,
    month: 0,
    year: 0
  };

  private pcaSubscription: Subscription;

  constructor(public activeModal: NgbActiveModal,
              private customerService: CustomerService,
              private alertService: AlertService,
              public errorService: ErrorService,
              public userService: UserService,
              private pcaService: PCAService) {
    this.listenForPcaAddressChange();
  }

  ngOnDestroy(): void {
    this.pcaSubscription.unsubscribe();
  }

  /**
   * Listen for PCA Address Changes and upate our form model
   * @private
   */
  private listenForPcaAddressChange(): void {
    this.pcaSubscription = this.pcaService
      .pcaChangeEvent
      .subscribe(
        (pcaResponse: PCAAddress) => {
          const ukcAddy = AddressFormatter.translateLoqateToUkc(pcaResponse);

          //Update our form model
          this.customerModel.abodenumber = ukcAddy.abodenumber;
          this.customerModel.straddr = ukcAddy.straddr;
          this.customerModel.street = ukcAddy.street;
          this.customerModel.building_name = ukcAddy.building_name;
          this.customerModel.city = ukcAddy.city;
          this.customerModel.zip = ukcAddy.zip;
        });
  }

  /**
   * This is called from outside; the creator of this modal.
   * @param customer
   */
  loadFormModel(customer: Customer) {
    Object.keys(this.customerModel).forEach(key => this.customerModel[key] = customer[key]);

    // UKC-2371 default to true
    this.customerModel.forwardAddress = true;

    // Put date in format needed by NgDatepicker
    const t = customer.dob.split(/[- :]/);
    // this.customerModel.dob = {day: parseInt(t[2]), month: parseInt(t[1]), year: parseInt(t[0])}
    this.dob = {day: parseInt(t[2]), month: parseInt(t[1]), year: parseInt(t[0])};
  }

  save() {
    // Extract editable params from customer model to push to backend
    // Object.keys(this.customerModel).forEach(key => this.customerModel[key] = this.customer[key]);
    // this.customerModel.dob = this.getMysqlDate(this.dob);
    this.customerModel.dob = DateUtils.getMysqlDate(this.dob);

    this.customerService
      .update(this.customerModel.guid, this.customerModel)
      .subscribe({
        next: () => {
          this.alertService.success('Customer record updated');
          this.close();
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.handleValidationErrors(err);
        },
      });
  }

  close() {
    this.activeModal.close();
  }
}
