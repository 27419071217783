import {Component, Input} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, shareReplay} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'app-afid',
  templateUrl: './afid.component.html',
  styleUrls: ['./afid.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
  ],
})
export class AfidComponent {

  publisherName$: Observable<string>;
  private _afid: number;

  constructor(private http: HttpClient) {

  }

  @Input() set afid(value: number | null) {
    if (value && value != this._afid) {
      this.publisherName$ = this.http
        .get(`/api/afid/${value}`)
        .pipe(
          map((resp: Array<object>) => {
            if (resp.length > 0) {
              return resp[0]['company'];
            }
            return '';
          }),
          shareReplay(),
        );
    } else {
      this.publisherName$ = of('');
    }

    this._afid = value;
  }

  get afid(): number | string {
    return this._afid ? this._afid : '-';
  }

}
