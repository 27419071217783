import {Component, Input} from '@angular/core';
import {TransactionModel} from 'src/app/shared/models/transaction.model';
import {UserService} from 'src/app/shared/auth/services/user.service';
import {Observable} from 'rxjs';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'app-order-number',
  templateUrl: './order-number.component.html',
  styleUrls: ['./order-number.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
  ],
})
export class OrderNumberComponent {

  @Input() transaction: TransactionModel;
  canLinkToSticky$: Observable<boolean>;

  constructor(private userService: UserService) {
    this.canLinkToSticky$ = this.userService.hasAbility('link-to-stickyio');
  }
}
