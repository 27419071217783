import {Component, EventEmitter, input, Output} from '@angular/core';
import {Customer} from 'src/app/customer/models/customer.model';
import {NgbModal, NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {UserAbilityDirective} from 'src/app/shared/auth/user-ability.directive';
import {AddNoteModalComponent} from 'src/app/notes/add-note-modal/add-note-modal.component';
import {Note} from 'src/app/notes/models/note.model';

/**
 * Action button for the top right of the customer detail page
 */

@Component({
  selector: 'app-add-note-button',
  standalone: true,
  imports: [
    NgbTooltip,
    UserAbilityDirective,
  ],
  templateUrl: './add-note-button.component.html',
})
export class AddNoteButtonComponent {
  customer = input.required<Customer>();

  @Output() addedNote = new EventEmitter<void>();

  constructor(private modalService: NgbModal) {
  }

  /**
   * Opens AddNoteModal and emits on addedNote when finished
   */
  addNote(): void {
    const modalRef = this.modalService.open(AddNoteModalComponent);

    modalRef.componentInstance.note = new Note(
      this.customer().guid,
      this.customer().status,
      this.customer().fname,
      this.customer().lname,
    );
    modalRef.componentInstance.note.customer_type = 'customer';

    modalRef.result.then(() => this.addedNote.emit());
  }
}
