import {Component, OnInit, signal, ViewChild} from '@angular/core';
import {RefundModalComponent} from '../../transactions/refund-modal/refund-modal.component';
import {NgbModal, NgbPagination, NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {ChargebackModalComponent} from '../../transactions/chargeback-modal/chargeback-modal.component';
import {ScreenshotModalComponent} from '../../transactions/screenshot-modal/screenshot-modal.component';
import {TransactionModel} from 'src/app/shared/models/transaction.model';
import {combineLatest, startWith} from 'rxjs';
import {CommonModule} from '@angular/common';
import {OrderNumberComponent} from 'src/app/customer/customer-detail/customer-transactions/order-number/order-number.component';
import {DateTimeFormatPipe} from 'src/app/shared/pipes/date-time-format.pipe';
import {map, tap} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import {CustomerState} from 'src/app/shared/state/customer.state';

@Component({
  selector: 'app-customer-transactions',
  templateUrl: 'customer-transactions.component.html',
  styleUrls: ['customer-transactions.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    OrderNumberComponent,
    DateTimeFormatPipe,
    NgbTooltip,
    NgbPagination,
  ],
})
export class CustomerTransactionsComponent implements OnInit {

  /**
   * Current GUID of user from detail view
   */
  private guid: string;

  transactions = signal<TransactionModel[]>([]);
  @ViewChild('paginator', {static: true}) paginator: NgbPagination;

  pagination: any = {
    start: 0,
    end: 10,
    current_page: 1,
  };

  constructor(
    private modalService: NgbModal,
    private store: Store,
  ) {
  }

  ngOnInit() {
    const pageChange$ = this.paginator.pageChange
      .pipe(
        startWith(1),
        tap((page: number) => {
          this.pagination.current_page = page;
          this.pagination.start = page * 10 - 10;
          this.pagination.end = page * 10;
        }),
      );

    const customer$ = this.store.select(CustomerState.getCustomer)
      .pipe(
        tap(cust => this.guid = cust?.guid ?? ''),

        // Transactions come from the customer fetch
        map(cust => cust?.transactions ?? []),
      );

    combineLatest([pageChange$, customer$])
      .subscribe(([page, transactions]) => {
        this.pagination.current_page = page;
        this.pagination.start = page * 10 - 10;
        this.pagination.end = page * 10;

        this.transactions.set(transactions);
      });
  }


  openRefundModal(trans: TransactionModel): void {
    const modalRef = this.modalService.open(RefundModalComponent);
    modalRef.componentInstance.transaction = trans;
    modalRef.componentInstance.refundRequest.guid = this.guid;
  }

  openChargebackModal(trans: TransactionModel) {
    const modalRef = this.modalService.open(ChargebackModalComponent);
    modalRef.componentInstance.transaction = trans;
    modalRef.componentInstance.chargebackRequest.guid = this.guid;
  }

  openScreenshotModal(trans: TransactionModel) {
    const modalRef = this.modalService.open(ScreenshotModalComponent, {size: 'lg'});
    modalRef.componentInstance.trans_num = trans.trans_num;
    modalRef.componentInstance.guid = this.guid;
  }

  /**
   * Determine what to display in the Processor Name column for a specific transaction record
   */
  getTransactionProcessor(transaction: TransactionModel): string {
    if (transaction.is_stickyio) {
      return transaction.stickyio_gateway_name;
    }

    return transaction.processor_name;
  }
}
