import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {ProspectListComponent} from './prospect-list/prospect-list.component';
import {ProspectDetailComponent} from './prospect-detail/prospect-detail.component';
import {ProspectService} from './services/prospect.service';
import {ProspectEditModalComponent} from './prospect-edit-modal/prospect-edit-modal.component';
import {MarkContactedModalComponent} from './mark-contacted-modal/mark-contacted-modal.component';
import {OfferEmailModalComponent} from './offer-email-modal/offer-email-modal.component';
import {UkPhonePipe} from 'src/app/shared/pipes/uk-phone.pipe';
import {DateTimeFormatPipe} from 'src/app/shared/pipes/date-time-format.pipe';
import {CustomerNotesComponent} from 'src/app/notes/customer-notes/customer-notes.component';
import {NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';
import {ErrorComponent} from 'src/app/shared/error/error.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UkPhonePipe,
    DateTimeFormatPipe,
    CustomerNotesComponent,
    NgbInputDatepicker,
    ErrorComponent,
  ],
  providers: [
    ProspectService,
  ],
  declarations: [
    ProspectListComponent,
    ProspectDetailComponent,
    ProspectEditModalComponent,
    MarkContactedModalComponent,
    OfferEmailModalComponent,
    // CustomerNotesComponent
  ],
  exports: [RouterModule],
})
export class ProspectModule {
}
