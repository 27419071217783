import {Component} from '@angular/core';
import {LengthAwarePaginator} from '../../shared/models/LengthAwarePaginator';
import {NgbModal, NgbPagination} from '@ng-bootstrap/ng-bootstrap';
import {NoteDetailModalComponent} from '../../notes/note-detail-modal/note-detail-modal.component';
import {RetrievedNote} from 'src/app/notes/models/retrieved-note.model';
import {Observable} from 'rxjs';
import {CommonModule} from '@angular/common';
import {DateTimeFormatPipe} from 'src/app/shared/pipes/date-time-format.pipe';
import {Nl2BrPipe} from 'src/app/shared/pipes/nl2br.pipe';
import {CustomerState} from 'src/app/shared/state/customer.state';
import * as CustomerActions from 'src/app/shared/state/customer.actions';
import {AbstractPaginatedSection} from 'src/app/shared/abstract-paginated-section.directive';

@Component({
  selector: 'app-customer-notes',
  templateUrl: 'customer-notes.component.html',
  styleUrls: ['customer-notes.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    DateTimeFormatPipe,
    Nl2BrPipe,
    NgbPagination,
  ],
})
export class CustomerNotesComponent extends AbstractPaginatedSection {

  notesPage$: Observable<LengthAwarePaginator<RetrievedNote>>;

  constructor(
    private modalService: NgbModal,
  ) {
    super();

    this.notesPage$ = this.store.select(CustomerState.getNotes);
  }

  protected dataUpdate([page, customer]: readonly [any, any]): Observable<void> {
    return this.store.dispatch(new CustomerActions.UpdateNotes(customer.guid, page));
  }

  /**
   * open note modal that fetches note detail
   */
  viewNote(noteId: number) {
    const modalRef = this.modalService.open(NoteDetailModalComponent);
    modalRef.componentInstance.noteId = noteId;
  }

}
