<div class="card rs-card rs-card-blue">
  <div class="card-body">

    <div class="d-flex justify-content-between mb-1" id="section-header">
      <h5 class="me-auto">
        Bank Transactions
        <i class="fa-regular fa-circle-question" ngbTooltip="Only transactions from the customer's declared rent payment account are shown" container="body"></i>
      </h5>

      <div>
        <button
          (click)="flagSelectedRows()"
          class="btn btn-primary btn-sm"
          [disabled]="isFlagButtonDisabled()"
          title="Marks selected transactions as rent payments, queueing them for transmission">
          Flag Selected {{selectedRowsLabel()}}
        </button>
      </div>
      <div>{{transactionData.length}} Transactions</div>
      <div>Updated: {{rentInfo().transactions_pulled_at | dateTimeFormat}}</div>
    </div>

    <ag-grid-angular
      [gridOptions]="gridOptions"
      [rowData]="transactionData"
      (rowSelected)="rowSelected($event)">
    </ag-grid-angular>
  </div>
</div>
