import {tap} from 'rxjs/operators';
import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CustomerLite} from '../../models/customer-lite.model';
import {PwResetResponse} from 'src/app/customer/models/pw-reset-response.interface';
import * as CustomerActions from "../../../shared/state/customer.actions";
import {Store} from "@ngxs/store";
import {switchMap} from "rxjs";


@Injectable()
export class AccountService {

    /**
     * Notification to any subscribers of note save events
     * @type {EventEmitter<boolean>}
     */
    @Output() onAccountUpdateEvent = new EventEmitter<any>();


    constructor(private http: HttpClient, private store: Store) {
    }


    sendPasswordResetEmail(customer: CustomerLite) {
        const params = {
            guid: customer.guid,
            email: customer.email,
            product_id: customer.product_id
        };
        return this.http.post<PwResetResponse>('/api/account/passwordreset', params);
    }


    cancelAccount(cancelAccountRequest) {
        return this.http.post('/api/account/cancel', cancelAccountRequest).pipe(
          tap((data) => { this.notify(data); }),
          tap(() => this.store.dispatch(new CustomerActions.UpdateCustomer(cancelAccountRequest.guid, true)))
        );
    }


    reactivateAccount(reactivateAccountRequest) {
      return this.http.post('/api/account/reactivate', reactivateAccountRequest).pipe(tap(
        (data) => {
          this.notify(data);
        }));
    }


    /**
     * Notify any subscribers
     * @param event
     */
    notify(event) {
        this.onAccountUpdateEvent.emit(event);
    }
}
