import {User} from 'src/app/shared/models/user.model';
import {AlertStatus, InquiryTypeSet} from 'src/app/shared/models/inquiry-type-set.model';
import {LengthAwarePaginator} from 'src/app/shared/models/LengthAwarePaginator';
import {Alert} from 'src/app/customer/models/alert.model';
import {SmsAlert} from 'src/app/customer/models/sms-alert.model';
import {Pricepoint} from 'src/app/customer/pricepoint/model/pricepoint';


export class UpdateUser {
  static readonly type = '[Session] Update User';

  constructor(public user: User) {
  }
}

export class UpdateIsAuthenticated {
  static readonly type = '[Session] Update isAuthenticated';

  constructor(public isAuthenticated: boolean) {
  }
}

export class UpdateInquiryTypes {
  static readonly type = '[Session] Update inquiryTypes';

  constructor(public types: InquiryTypeSet) {
  }
}

/**
 * This is for updating dashboard alerts.  The could be for any customer.  For customer detail alerts, see CustomerState
 */
export class UpdateAlerts {
  static readonly type = '[Session] Update alerts';

  constructor(
    public status: AlertStatus,
    public inquiryType: string,
    public alerts: LengthAwarePaginator<Alert> | LengthAwarePaginator<SmsAlert>,
  ) {
  }
}

export class UpdateDownsellPricepoints {
  static readonly type = '[Session] Update downsell pricepoints';

  constructor(public prices: Pricepoint[]) {
  }
}

export class ClearState {
  static readonly type = 'ClearState';

  constructor() {
  }
}

