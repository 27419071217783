@if (threeds) {
<div>
  <span (click)="openInfoModal()" class="clickable">
    {{ threeds.success ? 'Success' : 'Decline' }} |
    {{ threeds.challenge_completed ? 'Challenged' : '-' }}
  </span>
</div>
} @else {
<div>
  No data
</div>
}
