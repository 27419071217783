import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {PricepointService} from '../services/pricepoint.service';
import {CustomerService} from '../../services/customer.service';
import {HttpErrorResponse} from '@angular/common/http';
import {AlertService} from "@ratespecial/core";
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {Subscription} from 'rxjs';
import {Pricepoint} from 'src/app/customer/pricepoint/model/pricepoint';
import {UpdatePricepointRequest} from 'src/app/customer/pricepoint/model/update-pricepoint-request';

@Component({
  selector: 'app-downsell-modal',
  templateUrl: './downsell-modal.component.html',
  styleUrls: ['./downsell-modal.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
  ],
})
export class DownsellModalComponent implements OnInit, OnDestroy {

  pricepoints: Pricepoint[];

  selectedPricepoint: Pricepoint;

  /**
   * The current price customer is paying
   */
  recurring_price: string = '';

  public form: UpdatePricepointRequest = {
    bsid: null,
    guid: null,
    recurring_price: null,
  };

  /**
   * Form validation errors
   */
  public errors: any;

  /**
   * Add reference to Object.keys function to template
   */
  public objectKeys = Object.keys;
  private subscription: Subscription;

  constructor(
    private alertService: AlertService,
    public activeModal: NgbActiveModal,
    private pricepointService: PricepointService,
    private customerService: CustomerService,
  ) {
  }

  /**
   * OnInit to wait for inputs
   */
  ngOnInit(): void {
    this.subscription = this.pricepointService.get()
      .subscribe(data => {
        this.pricepoints = data;
        this.form.bsid = this.getBsidForCurrentPrice();
      });
  }



  /**
   * Returns the BSID for current price customer is paying
   */
  private getBsidForCurrentPrice() {
    for (let pp of this.pricepoints) {
      if (pp.recurring_price === this.recurring_price) {
        return pp.bsid;
      }
    }
  }

  /**
   * On price selection change by user, update hidden value for price
   */
  onSelectionChange(pricepoint: Pricepoint) {
    this.selectedPricepoint = pricepoint;
    this.form.recurring_price = pricepoint.recurring_price;
  }

  /**
   * Save to database
   */
  save() {
    this.customerService
      .updatePricepoint(this.form)
      .subscribe({
        next: () => {
          this.alertService.success('Customer Pricepoint Updated');
          this.close();
        },
        error: (err: HttpErrorResponse) => {
          this.handleValidationErrors(err);
        },
      });
  }

  /**
   * Close the modal
   */
  close() {
    this.activeModal.close();
  }


  /**
   * Handle 422 Form Validation Errors
   * @param err
   */
  // TODO: Switch to the universal ErrorService & app-error component
  handleValidationErrors(err) {
    if (err.status == 422) {
      err.error.errors.map(error => {
        this.alertService.addApiError(error);
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
