import {RedirectCommand, ResolveFn, Router} from '@angular/router';
import {inject} from '@angular/core';
import {catchError, of, switchMap} from 'rxjs';
import {AlertService} from '@ratespecial/core';
import {Customer} from 'src/app/customer/models/customer.model';
import {Store} from '@ngxs/store';
import {UpdateCustomer} from 'src/app/shared/state/customer.actions';
import {CustomerState} from 'src/app/shared/state/customer.state';

export const customerDetailPageCustomerResolver: ResolveFn<Customer> = (route, state) => {
  const store = inject(Store);
  const alertService = inject(AlertService);
  const router = inject(Router);

  const guid = route.params['guid'];

  // This will ensure the guid is in the state properly.  May or may not API call
  return store.dispatch(new UpdateCustomer(guid))
    .pipe(
      // After customer in state, return customer
      switchMap(() => store.select(CustomerState.getCustomer)),

      // If anything goes wrong
      catchError(e => {
        if (e.status == 422) {
          alertService.error('Invalid GUID probably');
        }

        // Send us back to the dashboard
        return of(new RedirectCommand(router.parseUrl('/')));
      }),
    );
};
