import {Component, Input, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {AlertService as CustomerAlertService} from '../../customer/customer-detail/customer-alerts/services/alert.service';
import {AlertSearchOptions} from '../../customer/models/AlertSearchOptions';
import {ErrorService} from '../../shared/error/error.service';
import {AlertService} from "@ratespecial/core";
import {CommonModule} from '@angular/common';
import {DateTimeFormatPipe} from 'src/app/shared/pipes/date-time-format.pipe';
import {NgbPagination, NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {RouterLink} from '@angular/router';
import {UserAbilityDirective} from 'src/app/shared/auth/user-ability.directive';
import {LengthAwarePaginator} from 'src/app/shared/models/LengthAwarePaginator';
import {Alert} from 'src/app/customer/models/alert.model';
import {AlertStatus} from 'src/app/shared/models/inquiry-type-set.model';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    DateTimeFormatPipe,
    NgbTooltip,
    NgbPagination,
    RouterLink,
    UserAbilityDirective,
  ],
})
export class AlertsComponent implements OnInit {

  @Input()
  public inquiryType: string;

  @Input()
  public status: AlertStatus;

  @Input()
  public label: string;

  public alerts: LengthAwarePaginator<Alert>;
  private isFetching: boolean;

  constructor(
      private customerAlertService: CustomerAlertService,
      private alertService: AlertService,
      public errorService: ErrorService
  ) { }

  ngOnInit(): void {
    this.getAlerts(1);
  }

  onPageChange(page: number) {
    this.getAlerts(page);
  }

  getAlerts(page: number) {
    const alertSearchOptions: AlertSearchOptions = {
      status: this.status,
      inquiry_type: this.inquiryType, // 'BILLING_CONCERNS'
      page: page
    };

    if (!this.isFetching) {
      this.isFetching = true;

      this.customerAlertService.get(alertSearchOptions).subscribe({
        next: (lengthAwarePaginator) => {
          // @ts-expect-error Need type guard
          this.alerts = lengthAwarePaginator;
          this.isFetching = false;
        },
        error: (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            this.alertService.error('A Network Error Occurred');
          } else {
            err.error.errors.map(error => {
              this.alertService.addApiError(error);
            });
          }
        }
      });
    }
  }

  hideAlert(alert_id: number): void {
    this.customerAlertService.hide(alert_id).subscribe({
      next: (data) => {
        this.getAlerts(1);
        this.alertService.success('Alert Hidden');
      },
      error: (err: HttpErrorResponse) => {
        // handled by global error interceptor
      },
    });
  }


}
