<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Change AFID</h5>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
</div>

<div class="modal-body">

  <app-error></app-error>

  <form>

    <div class="mb-2 row">
      <label class="col-sm-4 col-form-label">Current AFID</label>
      <div class="col-sm-8 padding-top-5">
        <app-afid [afid]="customer.afid">-</app-afid>
      </div>
    </div>

    <div class="mb-2 row">
      <label class="col-sm-4 col-form-label" for="afid">New AFID</label>
      <div class="col-sm-8 padding-top-5">
        @if (canChange()) {
          <select id="afid" class="form-control" name="newAfid" [(ngModel)]="newAfid">
            <option *ngFor="let item of partnerList | keyvalue" [value]="item.key">
              {{ item.value.name }} ({{ item.key }})
            </option>
          </select>
        } @else {
          <div>
            <em class="text-secondary">Unable to change AFID</em>
          </div>
        }
      </div>
    </div>

  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="save()" *ngIf="canChange()">Save</button>
  <button type="button" class="btn btn-outline-secondary" (click)="close()">Close</button>
</div>





