@if (alerts?.total > 0) {
  <h3>SMS Alerts</h3>
  <div class="card rs-card" [ngClass]="'rs-card-red'">
    <div class="card-body">

      <div class="d-flex justify-content-between">
        <h5>SMS Alerts</h5>
        <p>{{alerts?.total}} Alerts</p>
      </div>

      <table class="table table-sm rs-table table-striped table-smsAlerts">
        <thead class="">
        <tr>
          <th>ID</th>
          <th>Created</th>
          <th>Resolved</th>
          <th>SMS ID</th>
          <th class="text-center">Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let alert of alerts?.data">
          <td>{{alert.id}}</td>
          <td>{{alert.dt_created | dateTimeFormat:'withtime' }}</td>
          <td >{{alert.dt_resolved | dateTimeFormat:'withtime' }}</td>
          <td >{{alert.sms_id}}</td>
          <!-- Actions -->
          <td class="text-center">
            <a [routerLink]="['/customer/detail/' + alert.customer_guid]"><i class="fa-solid fa-eye fa-lg" ngbTooltip="View Customer Details"></i></a>
            <i *ngIf="alert.status === 'closed' && canHideAlert" class="fa-solid fa-ban fa-lg clickable" ngbTooltip="Hide Alert" (click)="hideAlert(alert.id)"></i>
          </td>
        </tr>
        </tbody>
      </table>
    </div>


    <div class="card-footer d-flex justify-content-between">
      <ngb-pagination *ngIf="alerts"
                      (pageChange)="onPageChange($event)"
                      [collectionSize]="alerts?.total"
                      [(page)]="alerts.current_page"
                      [maxSize]="5" [rotate]="true"
                      [ellipses]="false"
                      [boundaryLinks]="true"
                      size="sm">
      </ngb-pagination>
      <p>Page: {{alerts?.current_page}}</p>
    </div>

  </div>
}
