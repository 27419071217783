import {Component, input} from '@angular/core';
import {RentDeclarationInfo} from 'src/app/rent-reporting/models/rent-declaration-info';
import {Store} from '@ngxs/store';
import {CustomerState} from 'src/app/shared/state/customer.state';
import {DateTimeFormatPipe} from 'src/app/shared/pipes/date-time-format.pipe';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationModalComponent} from 'src/app/shared/modal/confirmation-modal/confirmation-modal.component';
import {ApproveRentDeclarations} from 'src/app/shared/state/customer.actions';

@Component({
  selector: 'app-declaration-approval-button',
  standalone: true,
  imports: [
    DateTimeFormatPipe,
  ],
  templateUrl: './declaration-approval-button.component.html',
  styleUrl: './declaration-approval-button.component.scss',
})
export class DeclarationApprovalButtonComponent {
  rentDeclaration = input.required<RentDeclarationInfo>();
  approval = this.store.selectSignal(CustomerState.getDeclarationApproval);

  constructor(
    private store: Store,
    private modal: NgbModal,
  ) {
  }

  approveConfirm() {
    const customer = this.store.selectSnapshot(CustomerState.getCustomer);

    // Guard against the highly unlikely.
    if (customer.uid !== this.rentDeclaration().uid) {
      throw Error('Customer state does not match given rent declaration.');
    }

    // Build a confirmation modal to explain the action they're taking.
    const m = this.modal.open(ConfirmationModalComponent);
    m.componentInstance.title = 'Approve Rent Declarations'
    m.componentInstance.message = "You certify that you reviewed the customer's claims about rental payments, including the rental agreement.  Flagged transactions from this point should reflect the information given.";

    m.result.then(
      choice => {
        if (choice) {
          this.store.dispatch(new ApproveRentDeclarations(customer.guid, this.rentDeclaration().id))
        }
      }
    )
  }
}
