import {Component, Input} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DarkWebInfoInterface} from '../../models/dark-web-info.interface';
import {DarkWebInfoModalComponent} from '../../dark-web-info-modal/dark-web-info-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dark-web-info',
  templateUrl: './dark-web-info.component.html',
  styleUrls: ['./dark-web-info.component.scss'],
})
export class DarkWebInfoComponent {
  private _uid = 0;

  darkWebInfo: DarkWebInfoInterface = null;

  get uid(): number {
    return this._uid;
  }

  @Input()
  set uid(value: number) {
    this._uid = value;

    this.loadDarkwebInfo();
  }

  constructor(
    private http: HttpClient,
    private modalService: NgbModal) {
  }

  private loadDarkwebInfo(): void {
    this.http
      .get<DarkWebInfoInterface>(`/api/customer/${this.uid}/dark-web`)
      .subscribe(resp => {
        this.darkWebInfo = resp;
      });
  }

  click() {
    const modalRef = this.modalService.open(DarkWebInfoModalComponent);

    if (modalRef) {
      modalRef.componentInstance.darkWebInfo = this.darkWebInfo;
    }

    return modalRef;
  }
}
