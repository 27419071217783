import {Directive, inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbPagination} from '@ng-bootstrap/ng-bootstrap';
import {combineLatest, distinct, filter, Observable, startWith, Subscription, switchMap} from 'rxjs';
import {Store} from '@ngxs/store';
import {CustomerState} from 'src/app/shared/state/customer.state';
import {tap} from 'rxjs/operators';

/**
 * Supports a customer detail component who's data is a paginated data table.  This will manage the page change and customer change events.
 */

// @Directive only so Angular parses the abstract class
@Directive()
export abstract class AbstractPaginatedSection implements OnInit, OnDestroy {
  protected store = inject(Store);

  /**
   * Reference to the NgbPagination control on the page.  Only works with one at a time?
   */
  @ViewChild('pagination', {static: true}) pagination: NgbPagination;

  /**
   * Subscription to our page + customer observable
   */
  protected subscription: Subscription;

  /**
   * ngOnInit due to @ViewChild()
   */
  ngOnInit(): void {
    // Watch for page changes.  This is when the user clicks a new page, or when pagination.selectPage() is called.
    const pageChange$ = this.pagination.pageChange
      .pipe(
        startWith(1),
      );

    // Watch for customer changes.
    const customer$ = this.store.select(CustomerState.getCustomer)
      .pipe(
        filter(cust => cust != null),

        // Only get new events if it's a new customer.  This prevents us from refreshing if a price point has changed.
        distinct(({guid}) => guid),

        // Restart from page one.
        tap(() => this.pagination.selectPage(1)),
      );

    // Fetch a new page of data whenever the page or customer changes.
    this.subscription = combineLatest([pageChange$, customer$])
      .pipe(
        switchMap(this.dataUpdate.bind(this)),
      )
      .subscribe();
  }

  /**
   * Observable to switch to when either the page or customer object change.  This needs to dispatch to the store to fetch
   * data into the state
   * @param page      Current page number, per the NgbPagination events
   * @param customer  Current customer in the state
   */
  protected abstract dataUpdate([page, customer]): Observable<void>;

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
