import {Directive, effect, inject, input} from '@angular/core';
import {UserService} from 'src/app/shared/auth/services/user.service';
import {NgIf} from '@angular/common';

/**
 * Hides host element if UserService.hasAbility() returns false.  Note this must be a structural directive.  Put * in front.
 *
 * <div *appUserAbility="'can-reset-password'"></div>
 * <div *appUserAbility="['can-reset-password','can-do-something']"></div>
 * <div *appUserAbility="'can-reset-password'; and: alert.closed"></div>
 *
 * Source: https://www.reddit.com/r/Angular2/comments/1hb0qo7/enhanced_ngif_vs_new_control_flow_for/#lightbox
 */

@Directive({
  selector: '[appUserAbility]',
  standalone: true,
  hostDirectives: [{directive: NgIf}],
})
export class UserAbilityDirective {

  private ngIfDirective = inject(NgIf);
  private userService = inject(UserService);

  ability = input<string | string[]>('', {alias: "appUserAbility"});

  // Voodoo structural directive syntax.  See https://angular.dev/guide/directives/structural-directives#structural-directive-syntax-reference
  predicate = input(true, {alias: "appUserAbilityAnd"});

  constructor() {
    effect(() => {
      const user = this.userService.getSnapshot();

      this.ngIfDirective.ngIf = this.userService.hasAbilitySnapshot(this.ability(), user) && this.predicate();
    });
  }
}
