import {Component} from '@angular/core';
import {InquiryTypeService} from 'src/app/shared/services/inquiry-type.service';
import {InquiryTypeSet} from '../shared/models/inquiry-type-set.model';
import {PageTitleService} from '../shared/page/page-title.service';
import {CommonModule} from '@angular/common';
import {SmsAlertsComponent} from 'src/app/dashboard/sms/sms-alerts.component';
import {AlertsComponent} from 'src/app/dashboard/alerts/alerts.component';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    SmsAlertsComponent,
    AlertsComponent,
  ],
})
export class DashboardComponent {

  inquiryTypes$: Observable<InquiryTypeSet>;

  constructor(
    private inquiryTypeService: InquiryTypeService,
    private pageTitleService: PageTitleService,
  ) {
    this.pageTitleService.setTitle('Dashboard');

    this.inquiryTypeService.fetch().subscribe({
      next: () => console.log('Fetched new Inquiry Type set'),
      error: e => console.error(e),
    });

    this.inquiryTypes$ = this.inquiryTypeService.get();
  }

}
