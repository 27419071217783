import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {LengthAwarePaginator} from '../../shared/models/LengthAwarePaginator';
import {Note} from '../models/note.model';
import {RetrievedNote} from 'src/app/notes/models/retrieved-note.model';
import {tap} from 'rxjs/operators';
import {Subject} from 'rxjs';


@Injectable({providedIn: 'root'})
export class NoteService {

  private noteCreatedSubject = new Subject<void>();
  /**
   * Fired every time create() is called
   */
  noteCreated = this.noteCreatedSubject.asObservable();

  constructor(private http: HttpClient) {
  }


  /**
   * Retrieve a specific note by its ID
   */
  fetchById(id: number) {
    return this.http.get<RetrievedNote>(`api/note/${id}`);
  }


  /**
   * Create a new Note.  Will update customer state if applicable
   * @param note
   * @param isResolveNote     boolean denoting if note is to resolve an alert
   */
  create(note: Note, isResolveNote: boolean = false) {
    let url = 'api/note';
    if (isResolveNote) {
      url = 'api/note/resolve';
    }

    return this.http
      .post<Note>(url, note)
      .pipe(
        tap(() => {
          // Alert listeners a new note was created.  This needs to be replaced with state management
          this.noteCreatedSubject.next();
        })
      );
  }


  /**
   * Retrieve all notes for a specific user by GUID (paginated)
   */
  fetchByGuid(guid: string, page: number) {
    let params = new HttpParams();
    params = params.append('page', page);

    return this.http
      .get<LengthAwarePaginator<RetrievedNote>>(`api/customer/${guid}/notes`, {params: params});
  }

}
