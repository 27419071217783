import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbDateStruct, NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {ErrorService} from '../../shared/error/error.service';
import {FormsModule} from '@angular/forms';
import {ErrorComponent} from 'src/app/shared/error/error.component';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'app-customer-search-modal',
  templateUrl: './customer-search-modal.component.html',
  styleUrls: ['./customer-search-modal.component.css'],
  standalone: true,
  imports: [CommonModule, FormsModule, ErrorComponent, NgbInputDatepicker],
})
export class CustomerSearchModalComponent implements AfterViewInit {

  public memberstatusOptions = ['active', 'manual', 'prospect', 'cancelled', 'chargeback', 'returnreverse', 'thin_file'];
  @ViewChild('fnameInput') private fnameInput: ElementRef;

  /**
   * Form Model
   */
  public searchModel = {
    fname: '',
    lname: '',
    email: '',
    post_code: '',
    last_four: '',
    date_start: '',
    date_end: '',
    member_status: '',
    phone: '',
  };

  /**
   * Date Structures For Date Picker
   */
  public dateStart: NgbDateStruct = null;
  public dateEnd: NgbDateStruct = null;


  constructor(public activeModal: NgbActiveModal,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              public errorService: ErrorService) {
  }

  ngAfterViewInit(): void {
    this.fnameInput.nativeElement.focus();
  }

  /**
   * Handles form submit by placing search params on url and then navigating to the autosearch route
   */
  submit() {
    // Add params and navigate to autosearch url
    this.router.navigate(['/customer/search'], {queryParams: this.searchModel});
    this.close();
  }


  /**
   * Close the modal
   */
  close() {
    this.activeModal.close();
  }


  /**
   * Reset the form
   */
  reset() {
    Object
      .keys(this.searchModel)
      .map((key) => {
        this.searchModel[key] = '';
      });
  }
}
