import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NoteService} from '../services/note.service';
import {RetrievedNote} from 'src/app/notes/models/retrieved-note.model';
import {DateTimeFormatPipe} from 'src/app/shared/pipes/date-time-format.pipe';
import {Nl2BrPipe} from 'src/app/shared/pipes/nl2br.pipe';

@Component({
  selector: 'app-note-detail-modal',
  templateUrl: 'note-detail-modal.component.html',
  styleUrls: ['note-detail-modal.component.css'],
  standalone: true,
  imports: [
    DateTimeFormatPipe,
    Nl2BrPipe,
  ],
})
export class NoteDetailModalComponent implements OnInit {

  public noteId: number;
  public note: RetrievedNote;

  constructor(
    private noteService: NoteService,
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit() {
    this.noteService.fetchById(this.noteId).subscribe(data => {
      this.note = data;
    });
  }

  close() {
    this.activeModal.close();
  }
}
