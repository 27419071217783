import {filter, Observable} from 'rxjs';
import {map, share, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {User} from '../../models/user.model';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngxs/store';
import {SessionState} from 'src/app/shared/state/session.state';
import * as SessionActions from 'src/app/shared/state/session.actions';

@Injectable({providedIn: 'root'})
export class UserService {

  constructor(private http: HttpClient, private store: Store) {
  }

  get(): Observable<User> {
    return this.store
      .select(SessionState.getUser)
      .pipe(
        filter(user => user != null)
      );
  }

  getSnapshot(): User | null {
    return this.store.selectSnapshot(SessionState.getUser);
  }

  /**
   * Fetch User entity from server
   */
  fetch(): Observable<User> {
    return this.http
      .get<User>('/api/user')
      .pipe(
        share(),
        tap(user => this.store.dispatch(new SessionActions.UpdateUser(user)))
      );
  }

  /**
   * Return true if user has the named role
   */
  hasRole(roleName: string): boolean {
    const user = this.store.selectSnapshot<User>(SessionState.getUser);

    let hasRole = false;

    user.roles.map(role => {
      if (role === roleName) {
        hasRole = true;
      }
    });

    return hasRole;
  }


  /**
   * Return true if user has the named abilities
   */
  hasAbility(abilityName: string | string[]): Observable<boolean> {
    return this.get()
      .pipe(
        map(u => this.hasAbilitySnapshot(abilityName, u))
      );
  }

  /**
   * Return true if specified User has the named abilities
   */
  hasAbilitySnapshot(abilityName: string | string[], user: User) {
    const abilityArray = Array.isArray(abilityName) ? abilityName : [abilityName];
    const userAbilityNames = user.abilities.map((ability) => ability.name);

    return abilityArray.every((ability) => userAbilityNames.includes(ability));
  }
}
