import {Component} from '@angular/core';
import {NgbModal, NgbPagination, NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute} from '@angular/router';
import {AlertService} from './services/alert.service';
import {NoteDetailModalComponent} from 'src/app/notes/note-detail-modal/note-detail-modal.component';
import {LengthAwarePaginator} from 'src/app/shared/models/LengthAwarePaginator';
import {AddNoteModalComponent} from 'src/app/notes/add-note-modal/add-note-modal.component';
import {Note} from 'src/app/notes/models/note.model';
import {NoteService} from 'src/app/notes/services/note.service';
import {User} from 'src/app/shared/models/user.model';
import {CommonModule} from '@angular/common';
import {DateTimeFormatPipe} from 'src/app/shared/pipes/date-time-format.pipe';
import {Alert} from 'src/app/customer/models/alert.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Store} from '@ngxs/store';
import {CustomerState} from 'src/app/shared/state/customer.state';
import {UserAbilityDirective} from 'src/app/shared/auth/user-ability.directive';
import {SessionState} from 'src/app/shared/state/session.state';

@Component({
  selector: 'app-customer-alerts',
  templateUrl: 'customer-alerts.component.html',
  styleUrls: ['customer-alerts.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    DateTimeFormatPipe,
    NgbTooltip,
    NgbPagination,
    UserAbilityDirective,
  ],
})
export class CustomerAlertsComponent {

  private guid = '';
  public alerts: LengthAwarePaginator<Alert>;
  private isFetching: boolean = false;
  private readonly user: User;

  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private alertService: AlertService,
    private noteService: NoteService,
    private store: Store,
  ) {
    this.user = this.store.selectSnapshot(SessionState.getUser);

    // subscribe to note save events
    this.noteService
      .noteCreated
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.refresh());

    // Watch for GUID changes so we can update alert data
    this.route.params.pipe(takeUntilDestroyed()).subscribe(params => {
      this.guid = params['guid'];
      this.refresh();
    });
  }

  getAlerts(page) {
    if (!this.isFetching) {
      this.isFetching = true;

      this.alertService.fetchByGuid(this.guid, page).subscribe((lengthAwarePaginator) => {
        this.alerts = lengthAwarePaginator;
        this.isFetching = false;
      });
    }
  }

  /**
   * Open Note Modal and show details for the given Note Id
   */
  viewNote(noteId: number) {
    const modalRef = this.modalService.open(NoteDetailModalComponent);
    modalRef.componentInstance.noteId = noteId;
  }


  resolveAlert(alert: Alert) {
    const guid = this.route.snapshot.params['guid'];
    const customer = this.store.selectSnapshot(CustomerState.getCustomer);

    const modalRef = this.modalService.open(AddNoteModalComponent);
    modalRef.componentInstance.note = new Note(
      guid,
      customer.status,
      customer.fname,
      customer.lname,
    );
    modalRef.componentInstance.note.customer_type = 'customer';
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.alert = alert;
  }

  /**
   * Event handler for refetching  Alerts from server
   */
  private refresh() {
    this.getAlerts(1);
  }
}
