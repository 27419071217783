@if (approval()) {
  <!-- If the approval already happened, show who did it -->
  <span id="approver-name">{{ approval().approver_name }}</span> -
  <span id="approver-date">{{ approval().created_at | dateTimeFormat }}</span>
} @else if (rentDeclaration()) {
  <!-- If we have rent declarations that haven't been approved, allow them to -->
  <button (click)="approveConfirm()" class="btn btn-primary">Approve</button>
} @else {
 <!-- If there are no rent declarations, don't show anything -->
  -
}
