import {Observable, take} from 'rxjs';
import {share, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {InquiryTypeSet} from '../models/inquiry-type-set.model';
import {Store} from '@ngxs/store';
import * as SessionActions from 'src/app/shared/state/session.actions';
import {SessionState} from 'src/app/shared/state/session.state';

/**
 * Inquiry Types
 *
 * Fetch distinct inquiry types for open and closed alerts
 */

@Injectable({providedIn: 'root'})
export class InquiryTypeService {

  private expireMinutes = 5;
  private timestamp: Date|null = null;

  constructor(private http: HttpClient, private store: Store) {
  }


  /**
   * Fetch data from server
   */
  fetch() {
    if (! this.isExpired()) {
      return this.get().pipe(take(1));
    }

    return this.http
      .get<InquiryTypeSet>('/api/alerts/inquiry-types')
      .pipe(
        share(),
        tap(resp => {
            this.timestamp = new Date();
            this.store.dispatch(new SessionActions.UpdateInquiryTypes(resp));
          },
        ),
      );
  }

  /**
   * Return cached data if exists
   */
  get(): Observable<InquiryTypeSet> {
    return this.store.select(SessionState.getInquiryTypes);
  }


  /**
   * Check if cached data is older than X minutes
   */
  isExpired(): boolean {
    if (!this.timestamp) {
      return true;
    }

    let isExpired = false;
    const now = new Date();
    const differenceInMinutes = Math.floor((now.getTime() - this.timestamp.getTime()) / (1000 * 60 * 60 * 60));

    if (differenceInMinutes > this.expireMinutes) {
      isExpired = true;
    }

    return isExpired;
  }
}
