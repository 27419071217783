import {Component, computed, OnDestroy, signal} from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {FlagRentTransactions, UpdateBankTransactions} from 'src/app/shared/state/customer.actions';
import {CustomerState} from 'src/app/shared/state/customer.state';
import {distinct, filter, Subscription} from 'rxjs';
import {AgGridAngular} from 'ag-grid-angular';
import {GridOptions, RowSelectedEvent} from 'ag-grid-community';
import {Store} from '@ngxs/store';
import {DateTimeFormatPipe} from 'src/app/shared/pipes/date-time-format.pipe';
import {BudTransaction} from 'src/app/rent-reporting/models/bud-transaction';
import {AlertService} from '@ratespecial/core';
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-banking-transactions',
  standalone: true,
  imports: [
    CommonModule,
    AgGridAngular,
    NgbTooltip,
    DateTimeFormatPipe,
  ],
  templateUrl: './banking-transactions.component.html',
  styleUrl: './banking-transactions.component.scss',
  providers: [
    CurrencyPipe,
    DateTimeFormatPipe,
  ]
})
export class BankingTransactionsComponent implements OnDestroy {
  /**
   * Row data for the grid
   */
  transactionData: BudTransaction[] = [];

  /**
   * Options for ag-grid.  Type-hinting works great here.
   */
  gridOptions: GridOptions<BudTransaction> = {
    rowSelection: {
      mode: 'multiRow',
      isRowSelectable: node => !node.data?.flagged_at,
      hideDisabledCheckboxes: true,
    },
    pagination: true,
    defaultColDef: {
      flex: 1,
      minWidth: 100,
      resizable: true,
      filter: true,
      sortable: true,
    },
    columnDefs: [
      {
        headerName: 'Date',
        field: 'date_time_at',
        sort: 'desc',
        valueFormatter: params => this.dateTimePipe.transform(params.value, 'withtime'),
      },
      {
        headerName: 'Description',
        field: 'description',
      },
      {
        headerName: 'Amount',
        field: 'amount',
        cellClass: 'text-end',
        valueFormatter: params => this.currencyPipe.transform(params.value, params.node.data.currency),
      },
      {
        headerName: 'Flagged At',
        field: 'flagged_at',
        headerTooltip: 'When a transaction was flagged as a rent payment',
        valueFormatter: params => this.dateTimePipe.transform(params.value, 'withtime'),
      },
      {
        headerName: 'Batch ID',
        field: 'batch_id',
        headerTooltip: 'Having a batch means the transaction was sent to the credit agency as a rent payment',
      },
    ],
  };

  private transactionsSub: Subscription;
  private customerSub: Subscription;

  declarationApproval = this.store.selectSignal(CustomerState.getDeclarationApproval);
  rentInfo = this.store.selectSignal(CustomerState.getRentInfo);

  /**
   * Currently selected rows, gleaned from rowSelected() event handler.
   */
  selectedRows = signal<BudTransaction[]>([]);
  /**
   * String that goes in the Flag Selected button to show how many rows are selected
   */
  selectedRowsLabel = computed<string>(() => {
    if (this.selectedRows().length > 0) {
      return ` [${this.selectedRows().length}]`;
    } else {
      return '';
    }
  });
  /**
   * There are requirements to being able to flag transactions
   */
  isFlagButtonDisabled = computed<boolean>(() => this.selectedRows().length === 0 || !this.declarationApproval());

  constructor(
    private store: Store,
    private currencyPipe: CurrencyPipe,
    private dateTimePipe: DateTimeFormatPipe,
    private alertService: AlertService,
  ) {
    this.transactionsSub = this.store.select(CustomerState.getBankTransactions).subscribe({
      next: res => {
        if (res) {
          this.transactionData = res.data;
        } else {
          this.transactionData = [];
        }
      },
      error: err => console.error(err),
    });

    this.watchCustomerChanges();
  }

  private watchCustomerChanges() {
    // Watch for customer changes.
    this.customerSub = this.store.select(CustomerState.getCustomer)
      .pipe(
        filter(cust => cust != null),

        // Only get new events if it's a new customer.  This prevents us from refreshing if a price point has changed.
        distinct(({guid}) => guid),
      )
      .subscribe({
        next: cust => this.store.dispatch(new UpdateBankTransactions(cust.guid, 1)),
      });
  }

  ngOnDestroy(): void {
    this.transactionsSub.unsubscribe();
    this.customerSub.unsubscribe();
  }

  flagSelectedRows(): void {
    if (confirm('This will mark the selected transactions as rent payments, queueing them for transmission to the credit agency.')) {
      this.store
        .dispatch(new FlagRentTransactions(this.selectedRows()))
        .subscribe({
          next: () => this.alertService.success('Success'),
          error: e => {
            console.error(e);
            this.alertService.error(e.error.message);
          }
        });
    }
  }

  rowSelected($event: RowSelectedEvent<any>): void {
    this.selectedRows.set($event.api.getSelectedRows());
  }
}
