import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CustomerService} from '../services/customer.service';
import {AlertService} from "@ratespecial/core";
import {Customer} from 'src/app/customer/models/customer.model';
import {ErrorComponent} from 'src/app/shared/error/error.component';
import {AfidComponent} from 'src/app/customer/customer-detail/afid/afid.component';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

@Component({
  selector: 'app-change-afid-modal',
  templateUrl: './change-afid-modal.component.html',
  styleUrls: ['./change-afid-modal.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ErrorComponent,
    AfidComponent,
  ],
})
export class ChangeAfidModalComponent implements OnInit {

  public partnerList: any = [];
  public newAfid: string;
  public customer = {
    guid: '',
    fname: '',
    lname: '',
    phone: '',
    afid: '',
  };

  constructor(public activeModal: NgbActiveModal,
              private alertService: AlertService,
              private customerService: CustomerService) {
  }

  ngOnInit(): void {
    // get partner list
    this.getPartnerList();
  }

  loadCustomer(customer: Customer) {
    Object.keys(this.customer).forEach(key => this.customer[key] = customer[key]);
  }

  canChange() {
    return !(this.customer.afid in this.partnerList);
  }

  getPartnerList() {
    this.customerService
      .getPartnerList()
      .subscribe({
        next: (data) => {
          this.partnerList = data;
        }
      });
  }

  save() {
    if (this.newAfid !== undefined) {
      const customer = {...this.customer};
      customer.afid = this.newAfid;

      this.customerService
        .update(this.customer.guid, customer)
        .subscribe({
          next: () => {
            this.alertService.success('Customer AFID Changed Successfully');
            this.close();
          }
        });
    }
  }

  close() {
    this.activeModal.close();
  }
}
