import {tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Subject} from 'rxjs';
import {RefundRequest} from 'src/app/customer/models/refund-request.interface';


@Injectable({providedIn: 'root'})
export class RefundService {

  private refundedSubject = new Subject<RefundRequest>();
  /**
   * Fired whenever a new refund is created using refund()
   */
  refunded = this.refundedSubject.asObservable();

  constructor(private http: HttpClient) {
  }

  refund(refundRequest: RefundRequest) {
    return this.http
      .post('api/transaction/refund', refundRequest)
      .pipe(
        tap(() => this.refundedSubject.next(refundRequest)));
  }
}
