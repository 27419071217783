@let batches = batches$ | async;

<div class="card rs-card rs-card-blue">
  <div class="card-body">
    <div class="d-flex justify-content-between align-items-center mb-1" id="section-header">
      <h5 class="me-auto">
        SHARE Batches
        <i class="fa-regular fa-circle-question" ngbTooltip="Batches are sets of transactions sent to the credit agency" container="body"></i>
      </h5>

      <div>{{ batches?.length }} Batches</div>
    </div>

    @if (batches?.length > 0) {
      <table class="margin-top-15 table table-sm rs-table table-striped">
        <thead class="">
        <tr>
          <th>Batch ID</th>
          <th>Date Submitted</th>
        </tr>
        </thead>
        <tbody>
          @for (b of batches; track b.id) {
            <tr>
              <td>{{ b.id }}</td>
              <td>{{ b.submitted_at | dateTimeFormat:'withtime' }}</td>
            </tr>
          }
        </tbody>
      </table>
    } @else {
      <div class="text-center">No transactions have been sent to the agency for this user.</div>
    }
  </div>
</div>
