<div class="hstack gap-3">
  <h4>Recent Transactions</h4>
  <div class="ms-auto small"><i class="fa-solid fa-circle" aria-hidden="true"></i> Full Refund</div>
  <div class="small"><i class="fa-regular fa-circle" aria-hidden="true"></i> Partial Refund</div>
</div>

<div class="card rs-card rs-card-blue">
  <div class="card-body" [ngClass]="{'min-paginated-card-height': transactions()?.length >= 10}">

    <div>
      <p class="text-end">{{transactions()?.length}} Transactions</p>
    </div>

    <table class="table table-sm rs-table table-striped table-transactions">
      <thead class="">
      <tr>
        <th>Trans Num</th>
        <th>Date</th>
        <th>Processor</th>
        <th>Type</th>
        <th>Amount</th>
        <th>RF</th>
        <th>CB</th>
        <th>RR</th>
        <th class="text-center">Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let transaction of transactions() | slice:pagination.start:pagination.end">
        <td><app-order-number *ngIf="!!transaction.trans_num" [transaction]="transaction"></app-order-number></td>
        <td>{{transaction.dt | dateTimeFormat:'withtime' }}</td>
        <td>{{ getTransactionProcessor(transaction) }}</td>
        <td>{{transaction.transaction_name}}</td>
        <td>{{transaction.amount}}</td>
        <td>
          <i class="fa-solid fa-circle" aria-hidden="true" *ngIf="transaction.isRefundableType && transaction.available_refund == 0"></i>
          <i class="fa-regular fa-circle" aria-hidden="true" *ngIf="transaction.available_refund > 0 && transaction.available_refund < transaction.amount"></i>
        </td>
        <td><i class="fa-regular fa-square-check" aria-hidden="true" *ngIf="transaction.hasChargeback"></i></td>
        <td><i class="fa-regular fa-square-check" aria-hidden="true" *ngIf="transaction.hasReturnReversal"></i></td>
        <td class="text-center">
          <div class="d-inline-block clickable" tabindex="0" (click)="openRefundModal(transaction)" *ngIf="transaction.canRefund" ngbTooltip="Refund">
            <i class="fa-solid fa-arrow-down fa-lg"></i>
          </div>
          <div class="d-inline-block clickable" tabindex="0" (click)="openChargebackModal(transaction)" *ngIf="transaction.canChargeback" ngbTooltip="Log Chargeback">
            <i class="fa-regular fa-credit-card fa-lg"></i>
          </div>
          <div class="d-inline-block clickable" tabindex="0" (click)="openScreenshotModal(transaction)" *ngIf="transaction.canReturnReversal" ngbTooltip="Open Screenshot">
            <i class="fa-regular fa-image fa-lg"></i>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="card-footer d-flex justify-content-between">
    <ngb-pagination #paginator
                    [collectionSize]="transactions().length"
                    [(page)]="pagination.current_page"
                    [maxSize]="5"
                    [rotate]="true"
                    [ellipses]="false"
                    [boundaryLinks]="true"
                    size="sm"></ngb-pagination>
    <p>Page: {{pagination?.current_page}}</p>
  </div>
</div>
<!--<pre>{{ transactions | json }}</pre>-->
