import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {ErrorService} from '../../shared/error/error.service';
import {CustomerService} from '../../customer/services/customer.service';
import {ErrorComponent} from 'src/app/shared/error/error.component';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'app-guid-search-modal',
  templateUrl: './guid-search-modal.component.html',
  styleUrls: ['./guid-search-modal.component.css'],
  standalone: true,
  imports: [CommonModule, ErrorComponent, FormsModule],
})
export class GuidSearchModalComponent implements AfterViewInit {

  public guid = '';

  @ViewChild('guidInput') private guidInput: ElementRef;

  constructor(
    private activeModal: NgbActiveModal,
    private router: Router,
    private customerService: CustomerService,
    public errorService: ErrorService) {
  }

  ngAfterViewInit(): void {
    this.guidInput.nativeElement.focus();
  }

  submit(): void {
    const guid = this.prepGuid();

    if (this.isAgencyGuid(guid)) {
      this.customerService
        .convertAgencyGuidToOurGuid(guid)
        .subscribe(ourGuid => {
          this.router.navigateByUrl('/customer/detail/' + ourGuid);
          this.close();
        });
    } else {
      this.router.navigateByUrl('/customer/detail/' + guid);
      this.close();
    }
  }

  private isAgencyGuid(guid: string): boolean {
    return guid.length == 36;
  }

  private prepGuid(): string {
    let guid = this.guid.trim();

    // Allow pasting from database IDEs
    if (guid.substring(0, 2) === '0x') {
      guid = guid.substring(2);
    }

    return guid;
  }

  close(): void {
    this.activeModal.close();
  }

  /**
   * Reset the form
   */
  reset(): void {
    this.guid = '';
  }
}
