import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css'],
  standalone: true,
  imports: [],
})
export class ConfirmationModalComponent {

  public title: string;
  public message: string;

  constructor(
    public activeModal: NgbActiveModal,
  ) {
  }

  yes() {
    this.activeModal.close(true);
  }

  no() {
    this.activeModal.close(false);
  }

  close() {
    this.activeModal.close(false);
  }
}
