<div class="container">
    <div>
        <app-sms-alerts></app-sms-alerts>
    </div>

    <div *ngIf="inquiryTypes$ | async" class="mt-5">
        <h3>Open Alerts</h3>
        <app-alerts
            status="open"
            [inquiryType]="intype.entity"
            [label]="intype.label"
            *ngFor="let intype of (inquiryTypes$ | async)?.open"
        ></app-alerts>
        <br/>

        <h3>Closed Alerts</h3>
        <app-alerts
            status="closed"
            [inquiryType]="intype.entity"
            [label]="intype.label"
            *ngFor="let intype of (inquiryTypes$ | async)?.closed"
        ></app-alerts>
    </div>
</div>
