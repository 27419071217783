import {Component} from '@angular/core';
import {LengthAwarePaginator} from 'src/app/shared/models/LengthAwarePaginator';
import {CommonModule} from '@angular/common';
import {DateTimeFormatPipe} from 'src/app/shared/pipes/date-time-format.pipe';
import {NgbPagination, NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {AbstractPaginatedSection} from 'src/app/shared/abstract-paginated-section.directive';
import {Observable} from 'rxjs';
import {CustomerState} from 'src/app/shared/state/customer.state';
import * as CustomerActions from 'src/app/shared/state/customer.actions';
import {SmsAlert} from 'src/app/customer/models/sms-alert.model';


@Component({
  selector: 'app-customer-sms-alerts',
  templateUrl: './customer-sms-alerts.component.html',
  styleUrls: ['./customer-sms-alerts.component.css'],
  standalone: true,
  imports: [CommonModule, DateTimeFormatPipe, NgbPagination, NgbTooltip],
})
export class CustomerSmsAlertsComponent extends AbstractPaginatedSection {

  smsAlerts$: Observable<LengthAwarePaginator<SmsAlert>>;

  constructor() {
    super();

    this.smsAlerts$ = this.store.select(CustomerState.getSmsAlerts);
  }

  protected dataUpdate([page, customer]: readonly [any, any]): Observable<any> {
    return this.store.dispatch(new CustomerActions.UpdateSmsAlerts(customer.guid, page));
  }

  resolveAlert(id: number) {
    this.store.dispatch(new CustomerActions.ResolveSmsAlert(id));
  }
}
