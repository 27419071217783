import {ErrorHandler, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {CustomerModule} from './customer/customer.module';
import {ProspectModule} from './prospect/prospect.module';
import {HotkeyModule, IHotkeyOptions} from 'angular2-hotkeys';
import {NgxsModule} from '@ngxs/store';
import {SessionState} from 'src/app/shared/state/session.state';
import {environment} from '../environments/environment';
import {AlertsComponent as RsAlertsComponent, LoadIndicatorComponent, LoadIndicatorInterceptor} from '@ratespecial/core';
import {provideRouter} from '@angular/router';
import {routes} from 'src/app/app-routes';
import {appErrorFactory, AppErrorHandler} from 'src/app/shared/error/app-error-handler.service';
import {CustomerState} from 'src/app/shared/state/customer.state';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {provideNgxWebstorage, withLocalStorage, withSessionStorage} from 'ngx-webstorage';
import {APP_CONFIG, AppConfig} from 'src/app/app.config';
import {ErrorService} from 'src/app/shared/error/error.service';
import {AuthSanctumInterceptor} from 'src/app/shared/interceptor/http/auth-sanctum.interceptor';
import {LogoutOnUnauthInterceptor} from 'src/app/shared/interceptor/http/logout-on-unauth.interceptor';
import {ServerErrorInterceptor} from 'src/app/shared/interceptor/http/server-error.interceptor';
import {BrowserModule} from '@angular/platform-browser';

const iHotkeyOptions: IHotkeyOptions = {

  disableCheatSheet: false,
  // cheatSheetHotkey: 'shift+h',
  // cheatSheetCloseEsc: true,
  // cheatSheetCloseEscDescription: 'Close Help Menu',
  cheatSheetDescription: 'Show/Hide this Help Menu',
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    CustomerModule,
    ProspectModule,
    HotkeyModule.forRoot(iHotkeyOptions),
    NgxsModule.forRoot([
      SessionState, CustomerState
    ], {
      developmentMode: !environment.production,
    }),
    LoadIndicatorComponent,
    RsAlertsComponent,
  ],
  providers: [
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),
    provideNgxWebstorage(
      withLocalStorage(),
      withSessionStorage(),
    ),
    // Use our custom AppErrorHandler to process Angular errors
    {provide: ErrorHandler, useFactory: appErrorFactory},
    // Alias AppErrorHandler to the ErrorHandler token so we can inject AppErrorHandler and configure it
    {provide: AppErrorHandler, useExisting: ErrorHandler},
    {provide: APP_CONFIG, useValue: AppConfig},
    ErrorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadIndicatorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthSanctumInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LogoutOnUnauthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true,
    },
  ],
})
export class AppModule {
}
