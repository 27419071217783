import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Pricepoint} from 'src/app/customer/pricepoint/model/pricepoint';
import {Store} from '@ngxs/store';
import {SessionState} from 'src/app/shared/state/session.state';
import * as SessionActions from 'src/app/shared/state/session.actions';
import {tap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class PricepointService {

  constructor(private http: HttpClient, private store: Store) {
  }

  get() {
    // If we have an empty state, fetch from the server
    if (this.store.selectSnapshot(SessionState.getDownsellPricepoints).length == 0) {
      this.http
        .get<Pricepoint[]>('api/pricepoint')
        .pipe(
          tap(prices => this.store.dispatch(new SessionActions.UpdateDownsellPricepoints(prices))),
        )
        .subscribe({
          next: () => console.debug('Updated downsell pricepoints'),
          error: e => console.error(e),
        });
    }

    // Always return from the state.
    return this.store.select(SessionState.getDownsellPricepoints);
  }
}
