import {Component, computed} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Customer} from 'src/app/customer/models/customer.model';
import {RouterLink} from '@angular/router';
import {AddNoteButtonComponent} from 'src/app/customer/customer-detail/action-buttons/add-note-button/add-note-button.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {filter} from 'rxjs';
import {AlertService} from '@ratespecial/core';
import {PageTitleService} from 'src/app/shared/page/page-title.service';
import {DateTimeFormatPipe} from 'src/app/shared/pipes/date-time-format.pipe';
import {Store} from '@ngxs/store';
import {CustomerState} from 'src/app/shared/state/customer.state';
import {DateTime} from 'luxon';
import {BankingTransactionsComponent} from 'src/app/rent-reporting/rent-page/banking-transactions/banking-transactions.component';
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {BatchesListComponent} from 'src/app/rent-reporting/rent-page/batches-list/batches-list.component';
import {
  DeclarationApprovalButtonComponent
} from 'src/app/rent-reporting/rent-page/declaration-approval-button/declaration-approval-button.component';

@Component({
  selector: 'app-rent-page',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    AddNoteButtonComponent,
    DateTimeFormatPipe,
    BankingTransactionsComponent,
    NgbTooltip,
    BatchesListComponent,
    DeclarationApprovalButtonComponent,
  ],
  templateUrl: './rent-page.component.html',
  styleUrl: './rent-page.component.scss'
})
export class RentPageComponent {
  customer: Customer;

  rent = this.store.selectSignal(CustomerState.getRentInfo);
  expectedRenewalDate = computed(() => {
    if (!this.rent().bud_connected) {
      return null;
    }

    const dt = DateTime.fromISO(this.rent().bud_connected_at);

    return dt.plus({days: 90});
  });

  constructor(
    private alertService: AlertService,
    pageTitleService: PageTitleService,
    private store: Store,
  ) {
    // Subscribe to store.  This will fire when fetch() finishes
    this.store.select(CustomerState.getCustomer)
      .pipe(
        takeUntilDestroyed(),
        filter(cust => cust != null),
      )
      .subscribe(customer => {
        this.customer = customer;

        if (customer.fullname) {
          pageTitleService.setTitle(customer.fullname);
        }
      });
  }

  addedNote() {

  }
}
