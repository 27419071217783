import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AddressHistory} from 'src/app/shared/models/address-history';
import {CommonModule} from '@angular/common';
import {DateTimeFormatPipe} from 'src/app/shared/pipes/date-time-format.pipe';

@Component({
  selector: 'app-address-history',
  templateUrl: './address-history.component.html',
  styleUrls: ['./address-history.component.scss'],
  standalone: true,
  imports: [CommonModule, DateTimeFormatPipe],
})
export class AddressHistoryComponent {

  public addressHistory: Array<AddressHistory>;

  constructor(
    public activeModal: NgbActiveModal
  ) {}

}
