import {Component} from '@angular/core';
import {DateTimeFormatPipe} from 'src/app/shared/pipes/date-time-format.pipe';
import {CommonModule} from '@angular/common';
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {Store} from '@ngxs/store';
import {CustomerState} from 'src/app/shared/state/customer.state';

@Component({
  selector: 'app-batches-list',
  standalone: true,
  imports: [
    CommonModule,
    DateTimeFormatPipe,
    NgbTooltip,
  ],
  templateUrl: './batches-list.component.html',
  styleUrl: './batches-list.component.scss',
})
export class BatchesListComponent {
  batches$ = this.store.select(CustomerState.getRentBatches);

  constructor(private store: Store) {

  }
}
