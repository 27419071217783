import {tap} from 'rxjs/operators';
import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DineCardEnrollModalComponent} from '../dine-card-enroll-modal/dine-card-enroll-modal.component';
import {CustomerLiteInterface} from '../models/customer-lite.interface';
import {Observable} from 'rxjs';
import {DineSubscriptionInterface} from '../models/dine-subscription.interface';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import {DineCardInfoModalComponent} from '../dine-card-info-modal/dine-card-info-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class DineCardService {

  @Output() onUpdateEvent = new EventEmitter<any>();

  constructor(private http: HttpClient,
              private modalService: NgbModal) {
  }

  subscribeToDineCard(guid: string, durationInMonths: number): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');

    return this.http
      .post('/api/customer/' + guid + '/dine-card/enroll', {duration: durationInMonths}, {headers}).pipe(
      tap(() => this.onUpdateEvent.emit()));
  }

  /**
   * Queries the server for detailed information on their subscription.
   */
  dineCardSubscriptionInfo(guid: string): Observable<DineSubscriptionInterface> {
    return this.http
      .get<DineSubscriptionInterface>(`/api/customer/${guid}/dine-card`);
  }

  /**
   * Displays modal to enroll the customer in the Dine card offer
   * @param customer
   */
  openDineCardOfferModal(customer: CustomerLiteInterface): NgbModalRef {
    const modalRef = this.modalService.open(DineCardEnrollModalComponent);

    if (modalRef) {
      modalRef.componentInstance.dineService = this;
      modalRef.componentInstance.customer = customer;
    }

    return modalRef;
  }

  /**
   * Opens a modal with detailed information on their Dine card subscription
   * @param subscription
   */
  openDineInfoModal(subscription: DineSubscriptionInterface): NgbModalRef {
    const modalRef = this.modalService.open(DineCardInfoModalComponent);

    if (modalRef) {
      modalRef.componentInstance.subscription = subscription;
    }

    return modalRef;
  }
}
