<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel" [ngSwitch]="alert">
    <span *ngSwitchCase="null">Add Customer Note</span>
    <span *ngSwitchDefault>Resolve Alert</span>  for {{note?.fname | capitalize}} {{note?.lname | capitalize}}
  </h5>

  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
</div>

<form>
<div class="modal-body">

  <!-- Errors -->
  <app-error></app-error>

    <input type="hidden" name="customer_guid" [(ngModel)]="note.customer_guid">
    <input type="hidden" name="customer_status" id="customer_status" [(ngModel)]="note.disposition">
    <input type="hidden" name="resolve_note_id" id="resolve_note_id" [(ngModel)]="note.resolve_note_id">
    <input type="hidden" name="alert_id" id="alert_id" [(ngModel)]="note.alert_id">

    <!-- Date -->
    <div class="form-group row">
      <label for="date" class="col-sm-4 col-form-label">Date</label>
      <div class="col-sm-8 col-form-label">
        {{note.date | date:'medium'}}

        <input type="hidden"
               id="date"
               name="date"
               required
               [(ngModel)]="note.date">
      </div>
    </div>

    <!-- User -->
    <div class="form-group row">
      <label for="user" class="col-sm-4 col-form-label">User</label>
      <div class="col-sm-8 col-form-label">
        <span id="user">{{(user$ | async)?.name}}</span>
      </div>
    </div>

    <!-- Channel Type -->
    <div class="form-group row">
      <label for="channel_type" class="col-sm-4 col-form-label">Channel Type</label>
      <div class="col-sm-8">
        <select class="form-control" id="channel_type" name="channel_type" [(ngModel)]="note.type" required [ngClass]="{'is-invalid' : errors && errors['type']}">
          <option value="1" selected="selected">Phone Inbound</option>
          <option value="2">Phone Outbound</option>
          <option value="3">Email Inbound</option>
          <option value="4">Email Outbound</option>
          <option value="5">Other</option>
        </select>
      </div>
    </div>

    <!-- Inquiry Type -->
    <div class="form-group row">
      <label for="inquiry_type" class="col-sm-4 col-form-label">Inquiry Type</label>
      <div class="col-sm-8">
        <select name="inquiry_type" id="inquiry_type" class="form-control inquiry_type" [(ngModel)]="note.inquiry_type" [ngClass]="{'is-invalid' : errors && errors['inquiry_type']}">
          <optgroup label="Billing / Payment">
            <option value="8" title="Billing Concerns" class="it-tooltip alertable">Billing Concerns</option>
            <option value="52" title="Marks a Billing Concern Escalation Resolved" class="it-tooltip resolvable">Billing Concerns Resolved</option>
            <option value="7" title="Billing Dates" class="it-tooltip alertable">Billing Dates</option>
            <option value="51" title="Marks a Billing Date Escalation Resolved" class="it-tooltip resolvable">Billing Dates Resolved</option>
            <option value="64" title="Chargeback" class="it-tooltip ">Chargeback</option>
            <option value="11" title="Refund Request Agent Decline" class="it-tooltip resolvable">Refund Request Agent Decline</option>
            <option value="10" title="Refund Request Agent Processed" class="it-tooltip resolvable">Refund Request Agent Processed</option>
            <option value="43" title="Refund Requested" class="it-tooltip alertable">Refund Requested</option>
            <option value="83" title="Chargeback dispute decided in our favor" class="it-tooltip ">Return Reversal</option>
            <option value="12" title="Other" class="it-tooltip alertable">Other</option>
            <option value="54" title="Marks a Billing/Payment Other Escalation Resolved" class="it-tooltip resolvable">Other Resolved</option>
          </optgroup>
          <optgroup label="Technical Inquiry ">
            <option value="14" title="Cant Access Report &amp; or Score" class="it-tooltip ">Cant Access Report &amp; or Score</option>
            <option value="18" title="Data Security" class="it-tooltip ">Data Security</option>
            <option value="16" title="Error message on site" class="it-tooltip ">Error message on site</option>
            <option value="17" title="Locked Out" class="it-tooltip ">Locked Out</option>
            <option value="15" title="Password Reset" class="it-tooltip ">Password Reset</option>
            <option value="13" title="Sign Up Problems" class="it-tooltip ">Sign Up Problems</option>
            <option value="65" title="Technical Escalation" class="it-tooltip alertable">Technical Escalation</option>
            <option value="66" title="Resolve a Technical Escalation" class="it-tooltip resolvable">Technical Escalation - RESOLVED</option>
            <option value="19" title="Other" class="it-tooltip ">Other</option>
          </optgroup>
          <optgroup label="Questions on Credit Report">
            <option value="9" title="Bank Statement Inquiry" class="it-tooltip alertable">Bank Statement Inquiry</option>
            <option value="53" title="Marks a Bank Statement Escalation Resolved" class="it-tooltip resolvable">Bank Statement Inquiry Resolved</option>
            <option value="20" title="Dispute Form Sent" class="it-tooltip ">Dispute Form Sent</option>
            <option value="23" title="Disputes Chase" class="it-tooltip ">Disputes Chase</option>
            <option value="21" title="Disputes Escalated" class="it-tooltip ">Disputes Escalated</option>
            <option value="22" title="Disputes Not Progressed" class="it-tooltip ">Disputes Not Progressed</option>
            <option value="28" title="Fraud Concern" class="it-tooltip ">Fraud Concern</option>
            <option value="24" title="Reading &amp; Understanding  report – RESOLVED" class="it-tooltip ">Reading &amp; Understanding  report – RESOLVED</option>
            <option value="25" title="Reading &amp; Understanding report – ESCALATED" class="it-tooltip ">Reading &amp; Understanding report – ESCALATED</option>
            <option value="42" title="Special Escalation" class="it-tooltip alertable">Special Escalation</option>
            <option value="44" title="Special Escalation - RESOLVED" class="it-tooltip resolvable">Special Escalation - RESOLVED</option>
            <option value="27" title="Understanding Credit Report Generally – ESCALTED" class="it-tooltip ">Understanding Credit Report Generally – ESCALTED</option>
            <option value="26" title="Understanding Credit Report Generally – RESOLVED" class="it-tooltip ">Understanding Credit Report Generally – RESOLVED</option>
            <option value="29" title="Other" class="it-tooltip ">Other</option>
          </optgroup>
          <optgroup label="Subscription Inquiry">
            <option value="31" title="Cancellation Request – DENIED" class="it-tooltip ">Cancellation Request – DENIED</option>
            <option value="30" title="Cancellation Request – PROCESSED" class="it-tooltip ">Cancellation Request – PROCESSED</option>
            <option value="34" title="Cost of Service" class="it-tooltip ">Cost of Service</option>
            <option value="32" title="Subscription Term Questions" class="it-tooltip ">Subscription Term Questions</option>
            <option value="33" title="Trial Period Questions" class="it-tooltip ">Trial Period Questions</option>
            <option value="35" title="Other" class="it-tooltip ">Other</option>
          </optgroup>
          <optgroup label="Brand – Product – Services">
            <option value="38" title="Marketing Partner Inquiry" class="it-tooltip ">Marketing Partner Inquiry</option>
            <option value="39" title="Press / Media Inquiry" class="it-tooltip ">Press / Media Inquiry</option>
            <option value="37" title="Who is CallCredit" class="it-tooltip ">Who is CallCredit</option>
            <option value="36" title="Who is RS DataTech / UKCreditRatings" class="it-tooltip ">Who is RS DataTech / UKCreditRatings</option>
            <option value="40" title="Other " class="it-tooltip ">Other </option>
          </optgroup>
          <optgroup label="General Inquiry">
            <option value="81" title="Customer sent an email" class="it-tooltip alertable">Email Inquiry</option>
            <option value="82" title="Resolved an Inquiry Email" class="it-tooltip resolvable">Email Inquiry - RESOLVED</option>
            <option value="41" title="General Inquiry" class="it-tooltip ">General Inquiry</option>
          </optgroup>
          <optgroup label="Prospect">
            <option value="47" title="Customer Prospect Requests Callback" class="it-tooltip ">Callback</option>
            <option value="46" title="Customer Prospect Contacted" class="it-tooltip ">Contacted</option>
            <option value="60" title="The customer was contacted and offered incentive to suscribe to the service" class="it-tooltip ">Contacted - Incentivized</option>
            <option value="61" title="The customer did not sign up for the service" class="it-tooltip ">Contacted - Never Signed Up</option>
            <option value="59" title="The customer was contacted but is not interested in the service" class="it-tooltip ">Contacted - Not Interested</option>
            <option value="62" title="The customer is unknown at the number contacted" class="it-tooltip ">Contacted - Person Doesn’t Exist</option>
            <option value="63" title="The customer did not knowingly subscribe to service" class="it-tooltip ">Contacted - Potential Fraud</option>
            <option value="58" title="The customer was contacted and would like to suscribe to the service" class="it-tooltip ">Contacted - Wants Service</option>
            <option value="48" title="Customer Prospect Requests No Further Contact" class="it-tooltip ">Do Not Contact</option>
            <option value="55" title="1st attempt to contact customer" class="it-tooltip ">Outbound 1</option>
            <option value="56" title="2nd attempt to contact customer" class="it-tooltip ">Outbound 2</option>
            <option value="57" title="3rd attempt to contact customer" class="it-tooltip ">Outbound 3</option>
          </optgroup>
          <optgroup label="Offers / Promotions">
            <option value="50" title="Email Offer" class="it-tooltip ">Email</option>
          </optgroup>
          <optgroup label="Fraud">
            <option value="68" title="Fraud" class="it-tooltip ">Credit Card</option>
            <option value="69" title="Fraud" class="it-tooltip ">Customer Never Signed Up</option>
          </optgroup>
          <optgroup label="Incentived">
            <option value="73" title="Customers acquired through cash offer" class="it-tooltip ">Cash</option>
            <option value="71" title="Customers acquired through coupon offer" class="it-tooltip ">Coupon</option>
            <option value="72" title="Customers acquired through points offer" class="it-tooltip ">Points</option>
          </optgroup>
          <optgroup label="Forced">
            <option value="76" title="Customers acquired as housing requirement" class="it-tooltip ">Housing</option>
            <option value="75" title="Customers acquired as employment requirement" class="it-tooltip ">Job</option>
            <option value="77" title="Customers acquired as requirement for other" class="it-tooltip ">Other</option>
          </optgroup>
          <optgroup label="Contact">
            <option value="80" title="Contact Statuses" class="it-tooltip ">Person Does Not Exist</option>
            <option value="79" title="Contact Statuses" class="it-tooltip ">Person Never Answers</option>
          </optgroup>

          <!-- Auto Generated Alerts -->
          <optgroup label="Auto Generated Alerts">
            <option value="87" title="Report Availability" class="it-tooltip ">Cannot retrieve report from TU - RESOLVED</option>
          </optgroup>

        </select>
      </div>
    </div>

    <!-- Disposition -->
    <div class="form-group row">
      <label class="col-sm-4 col-form-label">Disposition</label>
      <div class="col-sm-8 col-form-label">
        {{note.customer_status | titlecase}}
      </div>
    </div>

    <!-- Note Body -->
    <div class="form-group row">
      <label for="body" class="col-sm-4 col-form-label">Note</label>
      <div class="col-sm-8">
        <textarea
            class="form-control"
            id="body"
            name="body"
            rows="3"
            required
            [(ngModel)]="note.body"
            [ngClass]="{'is-invalid' : errors && errors['body']}"></textarea>
      </div>
    </div>

</div>

<div class="modal-footer">
  <button type="submit" class="btn btn-outline-primary" (click)="save()">Save</button>
  <button type="button" class="btn btn-outline-secondary" (click)="close()">Close</button>
</div>
</form>

<!--<pre>{{note | json}}</pre>-->
<!--<pre>{{alert | json}}</pre>-->
