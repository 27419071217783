import {Action, createSelector, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {User} from 'src/app/shared/models/user.model';
import {AlertStatus, InquiryTypeSet} from 'src/app/shared/models/inquiry-type-set.model';
import {
  ClearState,
  UpdateAlerts, UpdateDownsellPricepoints,
  UpdateInquiryTypes,
  UpdateIsAuthenticated,
  UpdateUser,
} from 'src/app/shared/state/session.actions';
import {LengthAwarePaginator} from 'src/app/shared/models/LengthAwarePaginator';
import {Alert} from 'src/app/customer/models/alert.model';
import {patch} from '@ngxs/store/operators';
import {SmsAlert} from 'src/app/customer/models/sms-alert.model';
import {Pricepoint} from 'src/app/customer/pricepoint/model/pricepoint';


type AlertCollection = { [key: string]: LengthAwarePaginator<Alert> | LengthAwarePaginator<SmsAlert> };

export interface SessionStateModel {
  user: User;
  isAuthenticated: boolean;

  inquiryTypes: InquiryTypeSet;
  alerts: AlertCollection
  downsellPricepoints: Pricepoint[];
}


const sessionStateDefaults: SessionStateModel = {
  user: null,
  isAuthenticated: false,

  inquiryTypes: undefined,
  alerts: {},
  downsellPricepoints: [],
};


@State<SessionStateModel>({
  name: 'session',
  defaults: sessionStateDefaults
})


@Injectable()
export class SessionState {

  @Selector()
  static getUser(state: SessionStateModel) {
    return state.user;
  }

  @Selector()
  static isAuthenticated(state: SessionStateModel) {
    return state.isAuthenticated;
  }

  @Selector()
  static getInquiryTypes(state: SessionStateModel) {
    return state.inquiryTypes;
  }

  @Selector()
  static getDownsellPricepoints(state: SessionStateModel) {
    return state.downsellPricepoints;
  }

  static getAlerts(status: AlertStatus, inquiryType: string) {
    return createSelector([SessionState], (state: SessionStateModel) => {
      const k = this.alertKey(status, inquiryType);

      return state.alerts[k] ?? null;
    });
  }

  @Action(UpdateUser)
  updateUser(ctx: StateContext<SessionStateModel>, action: UpdateUser) {
    ctx.patchState({user: action.user});
  }

  @Action(UpdateIsAuthenticated)
  updateIsAuthenticated(ctx: StateContext<SessionStateModel>, action: UpdateIsAuthenticated) {
    ctx.patchState({isAuthenticated: action.isAuthenticated});
  }

  @Action(UpdateInquiryTypes)
  updateInquiryTypes(ctx: StateContext<SessionStateModel>, action: UpdateInquiryTypes) {
    ctx.patchState({inquiryTypes: action.types});
  }

  @Action(UpdateAlerts)
  updateAlerts(ctx: StateContext<SessionStateModel>, action: UpdateAlerts) {
    const k = SessionState.alertKey(action.status, action.inquiryType);

    ctx.setState(patch<SessionStateModel>({
      alerts: patch<SessionStateModel['alerts']>({
        [k]: action.alerts
      }),
    }));
  }

  @Action(UpdateDownsellPricepoints)
  updateDownsellPricepoints(ctx: StateContext<SessionStateModel>, action: UpdateDownsellPricepoints) {
    ctx.patchState({downsellPricepoints: action.prices});
  }

  @Action(ClearState)
  clearState(ctx: StateContext<SessionStateModel>) {
    ctx.setState(sessionStateDefaults);
  }

  protected static alertKey(status: string, inquiryType: string): string {
    return `${status}-${inquiryType}`;
  }
}
