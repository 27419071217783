import {Component} from '@angular/core';
import {CustomerLite} from '../../models/customer-lite.model';
import {AccountService} from '../services/account.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ErrorService} from 'src/app/shared/error/error.service';
import {HttpErrorResponse} from '@angular/common/http';
import {AlertService} from "@ratespecial/core";
import {ErrorComponent} from 'src/app/shared/error/error.component';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

@Component({
  selector: 'app-reactivate-account-modal',
  templateUrl: './reactivate-account-modal.component.html',
  styleUrls: ['./reactivate-account-modal.component.css'],
  standalone: true,
  imports: [ErrorComponent, CommonModule, FormsModule],
})
export class ReactivateAccountModalComponent {

  /**
   * Customer Data needed for request
   */
  public customer: CustomerLite;

  /**
   * Reason select options
   */
  public reasons: Array<any> = [];

  public reactivateAccountRequest = {
    guid: null,
    note: ''
  };

  // Add reference to Object.keys function to template
  public objectKeys = Object.keys;


  constructor(
    private accountService: AccountService,
    private alertService: AlertService,
    public activeModal: NgbActiveModal,
    public errorService: ErrorService
  ) {
    this.errorService.clearErrors();
  }

  reactivateAccount() {
    this.errorService.clearErrors();
    this.reactivateAccountRequest.guid = this.customer.guid;

    this.accountService.reactivateAccount(this.reactivateAccountRequest)
      .subscribe({
        next: () => {
          this.alertService.success('Account has been reactivated.');
          this.close();
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.handleValidationErrors(err);
        }
      });
  }

  close() {
    this.activeModal.close();
  }
}
