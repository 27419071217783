/**
 * Used for creating new notes
 */
export class Note {
    public customer_guid?: any;
    public customer_status: string;
    public fname: string;
    public lname: string;
    public resolve_note_id: number;
    public alert_id: number;
    public date: any;
    public user_id: number;
    public type: string;    // channel_type
    public inquiry_type: string;
    public disposition: string;
    public body: string;
    public customer_type: string;      // customer or prospect

    constructor(
        customer_guid?: any,
        customer_status?: string,
        fname?: string,
        lname?: string,
        user_id?: number
    ) {
        this.customer_guid = customer_guid ? customer_guid : null;
        this.customer_status = customer_status ? customer_status : null;
        this.fname = fname ? fname : '';
        this.lname = lname ? lname : '';
        this.date = Date.now(); // don't use this to post back since it's the users local timezone
    }
}
