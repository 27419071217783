@if (alerts?.data.length > 0) {
<div class="card rs-card" [ngClass]="status === 'open' ? 'rs-card-red' : 'rs-card-green'">
  <div class="card-body">


    <div class="d-flex justify-content-between">
      <h5>{{label}}</h5>
      <p>{{alerts?.total}} Alerts</p>
    </div>

    <table class="table table-sm rs-table table-striped table-alerts">
      <thead class="">
      <tr>
        <th>ID</th>
        <th>Created</th>
        <th *ngIf="status=='closed'">Resolved</th>
        <th>Alert</th>
        <th>First</th>
        <th>Last</th>
        <th>Opened By</th>
        <th *ngIf="status=='closed'">Closed By</th>
        <th class="text-center">Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let alert of alerts?.data">
        <td>{{alert.note_id}}</td>
        <td>{{alert.dt_created | dateTimeFormat:'withtime' }}</td>
        <td *ngIf="status=='closed'">{{alert.dt_closed | dateTimeFormat:'withtime' }}</td>
        <td>{{alert.entity}}</td>
        <td>{{alert.fname}}</td>
        <td>{{alert.lname}}</td>
        <td>{{alert.username}}</td>
        <td *ngIf="status=='closed'">{{alert.resolved_username}}</td>
        <!-- Actions -->
        <td class="text-center">
          <div
            class="action-icon-wrap"
            [routerLink]="['/customer/detail/' + alert.customer_guid]"
            ngbTooltip="View Customer Details">
            <i class="fa-solid fa-eye fa-lg pe-2"></i>
          </div>

          <div
            class="action-icon-wrap"
            *appUserAbility="'hide-alert'; and: alert.status === 'closed'"
            ngbTooltip="Hide Alert"
            (click)="hideAlert(alert.id)">
            <i class="fa-solid fa-ban fa-lg"></i>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="card-footer d-flex justify-content-between">
    <ngb-pagination *ngIf="alerts"
                    (pageChange)="onPageChange($event)"
                    [collectionSize]="alerts?.total"
                    [(page)]="alerts.current_page"
                    [maxSize]="5" [rotate]="true"
                    [ellipses]="false"
                    [boundaryLinks]="true"
                    size="sm">
    </ngb-pagination>
    <p>Page: {{alerts?.current_page}}</p>
  </div>
</div>

}
