import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {LengthAwarePaginator} from 'src/app/shared/models/LengthAwarePaginator';
import {AlertSearchOptions} from 'src/app/customer/models/AlertSearchOptions';
import {Alert} from 'src/app/customer/models/alert.model';
import {finalize, share, tap} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import * as SessionActions from 'src/app/shared/state/session.actions';
import * as CustomerActions from 'src/app/shared/state/customer.actions';
import {Observable} from 'rxjs';
import {SessionState} from 'src/app/shared/state/session.state';
import {SmsAlert} from 'src/app/customer/models/sms-alert.model';

@Injectable({providedIn: 'root'})
export class AlertService {

  private smsFetch: Observable<LengthAwarePaginator<SmsAlert>> | null = null;
  private smsFetchByGuid: Observable<LengthAwarePaginator<SmsAlert>> | null = null;

  constructor(private http: HttpClient, private store: Store) {
  }

  /**
   * Query the server for all alerts.
   */
  fetchAll(guid: string) {
    return this.http.get(`api/alerts/${guid}`);
  }

  /**
   * Query the server for paginated alerts for a specific GUID.  Good for customer detail page.
   */
  fetchByGuid(guid: string, page: number) {
    let params = new HttpParams();
    params = params.append('page', page);

    return this.http
      .get<LengthAwarePaginator<Alert>>(`api/customer/${guid}/alerts`, {params: params})
      .pipe(
        tap(alerts => this.store.dispatch(new CustomerActions.UpdateAlerts(alerts))),
      );
  }

  /**
   * Query server for paginated SMS alerts for a specific GUID.
   */
  fetchSmsByGuid(guid: string, page: number) {
    if (! this.smsFetchByGuid) {
      let params = new HttpParams();
      params = params.append('page', page);

      this.smsFetchByGuid = this.http
        .get<LengthAwarePaginator<SmsAlert>>(`api/customer/${guid}/alerts/sms`, {params: params})
        .pipe(
          share(),
          finalize(() => this.smsFetchByGuid = null),
        );
    }

    return this.smsFetchByGuid;
  }

  /**
   * The current page from the state.  If requested page isn't in the state, we'll query the server
   */
  get(options: AlertSearchOptions) {
    return this.store
      .select(SessionState.getAlerts(options.status, options.inquiry_type))
      .pipe(
        tap(alerts => {
          // If the requested page isn't the one in the state, fetch from server
          if (!alerts || alerts.current_page != options.page) {
            let o;

            if (options.inquiry_type == 'sms') {
              o = this.fetchSms(options)
            } else {
              o = this.search(options);
            }

            o.subscribe({
              next: () => console.debug('Requested ' + options),
              error: e => console.error(e),
            });
          }
        })
      );
  }

  /**
   * Query the server for a specific category of alerts, paginated, storing in the state
   */
  search(options: AlertSearchOptions): Observable<LengthAwarePaginator<Alert>> {
    let params = new HttpParams();

    Object.keys(options).map((key) => {
      // params.set will completely replace previous object, so
      params = params.set(key, options[key]);
    });

    return this.http
      .get<LengthAwarePaginator<Alert>>('api/alerts', {params: params})
      .pipe(
        share(),
        tap(a => this.store.dispatch(new SessionActions.UpdateAlerts(options.status, options.inquiry_type, a))),
      );
  }

  fetchSms(options: AlertSearchOptions): Observable<LengthAwarePaginator<SmsAlert>> {
    if (! this.smsFetch) {
      this.smsFetch = this.http
        .get<LengthAwarePaginator<SmsAlert>>('api/alerts/sms', {params: {page: options.page}})
        .pipe(
          share(),
          tap(a => this.store.dispatch(new SessionActions.UpdateAlerts(options.status, options.inquiry_type, a))),
          finalize(() => this.smsFetch = null),
        );
    }

    return this.smsFetch;
  }

  hide(alert_id: number) {
    return this.http.get<Alert>(`api/alerts/hide/${alert_id}`);
  }

  resolveSmsAlert(id: number) {
    return this.http.get<SmsAlert>(`api/alerts/sms/${id}/resolve`);
  }
}
