import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from 'src/app/shared/auth/services/auth.service';
import {HttpErrorService} from 'src/app/shared/http/http-error.service';
import {HistoryService} from 'src/app/shared/auth/services/history.service';
import {LoadIndicatorComponent, LoadIndicatorService} from "@ratespecial/core";


@Component({
  selector: 'app-google',
  templateUrl: 'google.component.html',
  styleUrls: ['google.component.css'],
  providers: [LoadIndicatorService],
  standalone: true,
  imports: [LoadIndicatorComponent],
})
export class GoogleComponent implements OnInit {

  constructor(private authService: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              private loadIndicatorService: LoadIndicatorService,
              private httpErrorService: HttpErrorService,
              private historyService: HistoryService) {
  }

  ngOnInit() {
    // NOTE: this component provides its own LoadIndicatorService, so this is separate from the root one.
    this.loadIndicatorService.push('google-auth');

    const googleCode = this.route.snapshot.queryParams['code'];

    this.authService.setCsrfCookie().subscribe(() => {
      this.authService.authenticateGoogleCode(googleCode).subscribe({
        next: () => {
          this.gotoLandingPage();
        },
        error: err => {
          this.httpErrorService.handleError(err);
          this.router.navigateByUrl('/auth/login');
        },
      });
    });

  }

  private gotoLandingPage() {
    const lastVisitedRoute = this.historyService.consumeLastVisitedRoute();

    if (lastVisitedRoute) {
      this.router.navigateByUrl(lastVisitedRoute);
    } else {
      this.router.navigateByUrl('/dashboard');
    }
  }
}
