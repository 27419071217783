<div class="container">
  <div class="d-flex justify-content-between align-items-center">

    <h2 class="p-2">{{ customer?.fullname }}</h2>

    <button class="me-auto ms-3 btn btn-outline-secondary" [routerLink]="['/customer/detail/', customer?.guid]">Details</button>

    <!-- Tool Bar -->
    <div class="p-10">
      <div class="d-flex flex-row">

        <app-add-note-button class="p-2" [customer]="customer" (addedNote)="addedNote()"/>

        <!--<div class="p-2" ngbDropdown>
          <div class="clickable" ngbDropdownToggle>
            <i class="fa-solid fa-lg" aria-hidden="true" ngbTooltip="Menu"></i>
          </div>
          <div ngbDropdownMenu>
            <button (click)="refreshReport()" ngbDropdownItem>Refresh Report</button>
          </div>
        </div>-->
      </div>
    </div>
  </div>

  <div class="card rs-card rs-card-blue">
    <div class="card-body">
      <div class="row">
        <div class="col-sm-6">
          <dl class="row">
            <dt class="col-5">
              Banking Connection
              <i class="fa-regular fa-circle-question" ngbTooltip="Has customer completed open banking connection" container="body"></i>
            </dt>
            <dd class="col-7">{{ rent().bud_connected ? 'Yes - ' + (rent().bud_connected_at | dateTimeFormat) : 'No' }}</dd>
            <dt class="col-5">
              Expected Renewal Date
              <i class="fa-regular fa-circle-question" ngbTooltip="Last date open banking connection will be available before it needs to be reconnected" container="body"></i>
            </dt>
            <dd class="col-7">{{ expectedRenewalDate() | dateTimeFormat }}</dd>

          </dl>
        </div>

        <div class="col-sm-6">
          <dl class="row">
            <dt class="col-5">
              Rent Declaration Date
              <i class="fa-regular fa-circle-question" ngbTooltip="When customer completed Rent Declaration form in member portal" container="body"></i>
            </dt>
            <dd class="col-7">{{ (rent().rent_info[0]?.created_at | dateTimeFormat:'withtime') ?? '-' }}</dd>
            <dt class="col-5">
              Bank Account
              <i class="fa-regular fa-circle-question" ngbTooltip="Customer's bank account they pay rent from.  Bank transaction section only shows data from this account." container="body"></i>
            </dt>
            <dd class="col-7">{{ rent().rent_info[0]?.payment_account_name ?? '-' }}</dd>
            <dt class="col-5">Payment Amount</dt>
            <dd class="col-7">{{ rent().rent_info[0]?.payment_amount ?? '-' }}</dd>
            <dt class="col-5">Payment Frequency</dt>
            <dd class="col-7">{{ rent().rent_info[0]?.payment_frequency ?? '-' }}</dd>
            <dt class="col-5">
              Payment Start At
              <i class="fa-regular fa-circle-question" ngbTooltip="When they first start paying rent" container="body"></i>
            </dt>
            <dd class="col-7">{{ rent().rent_info[0]?.payment_start_at ?? '-' }}</dd>
            <dt class="col-5">
              Payee Name
              <i class="fa-regular fa-circle-question" ngbTooltip="Name of party that receives the customer's rent payments.  Should match transaction description." container="body"></i>
            </dt>
            <dd class="col-7">{{ rent().rent_info[0]?.payee_name ?? '-' }}</dd>
            <dt class="col-5">
              Rental Agreement
              <i class="fa-regular fa-circle-question" ngbTooltip="The landlord / tenant contract" container="body"></i>
            </dt>
            <dd class="col-7">
              @if (rent().rent_info[0]?.rental_agreement) {
                <a [href]="rent().rent_info[0]?.rental_agreement" target="_blank">View</a>
              }
            </dd>
            <dt class="col-5">
              Approval
              <i class="fa-regular fa-circle-question" ngbTooltip="Rental declarations must be approved before transactions can be flagged" container="body"></i>
            </dt>
            <dd class="col-7">
              <app-declaration-approval-button [rentDeclaration]="rent().rent_info[0]"></app-declaration-approval-button>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>

  <app-banking-transactions></app-banking-transactions>

  <div class="row">
    <div class="col-md-5">
      <app-batches-list></app-batches-list>
    </div>

    <div class="col-md-7">
      &nbsp;
    </div>
  </div>
</div>
