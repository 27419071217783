<div  class="padding-top-35" *ngIf="alerts?.total > 0">
    <h4>Alerts</h4>
    <div class="card rs-card rs-card-blue">
        <div class="card-body" [ngClass]="{'min-paginated-card-height': alerts?.total >= 10}">

            <div class="clearfix">
                <p class="text-end">{{alerts?.total}} Alerts</p>
            </div>

            <table class="table table-sm rs-table table-striped table-alerts">
                <thead class="">
                <tr>
                    <th><i class="fa-solid fa-triangle-exclamation"></i></th>
                    <th>Alert</th>
                    <th>Status</th>
                    <th>Created</th>
                    <th>Opened By</th>
                    <th>Closed</th>
                    <th>Closed By</th>
                    <th class="text-center">Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let alert of alerts?.data">
                    <td>
                        <span [ngSwitch]="alert.status">
                            <i *ngSwitchCase="'open'" class="fa-solid fa-triangle-exclamation font-red"></i>
                            <i *ngSwitchCase="'closed'" class="fa-regular fa-circle font-green"></i>
                      </span>
                    </td>
                    <td>{{alert.entity}}</td>
                    <td>{{alert.status}}</td>
                    <td>{{alert.dt_created | dateTimeFormat:'withtime'}}</td>
                    <td>{{alert.username}}</td>
                    <td>
                        <span *ngIf="alert.status==='closed'">{{alert.dt_closed | dateTimeFormat:'withtime' }}</span>
                    </td>
                    <td>{{alert.resolved_username}}</td>
                    <td class="text-center">
                      <div class="d-inline-block clickable" (click)="viewNote(alert.note_id)" ngbTooltip="View Note Details">
                        <i class="fa-regular fa-note-sticky fa-lg"></i>
                      </div>

                      @if (alert.resolved_note_id) {
                      <div class="d-inline-block clickable" (click)="viewNote(alert.resolved_note_id)"  ngbTooltip="View Resolved Note Details">
                        <i class="fa-regular fa-note-sticky fa-lg"></i>
                      </div>
                      }

                      @if (alert.status === 'open') {
                      <div
                        class="d-inline-block clickable"
                        *appUserAbility="'resolve-alert'"
                        (click)="resolveAlert(alert)"
                        ngbTooltip="Resolve the Alert">
                        <i class="fa-solid fa-rotate-left fa-lg"></i>
                      </div>
                      }
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="card-footer d-flex justify-content-between">
            <ngb-pagination *ngIf="alerts"
                            (pageChange)="getAlerts($event)"
                            [collectionSize]="alerts?.total"
                            [(page)]="alerts.current_page"
                            [pageSize]="alerts?.per_page"
                            [maxSize]="5"
                            [rotate]="true"
                            [ellipses]="false"
                            [boundaryLinks]="true"
                            size="sm"></ngb-pagination>
          <p>Page: {{alerts?.current_page}}</p>
        </div>
    </div>
</div>
