import {LengthAwarePaginator} from 'src/app/shared/models/LengthAwarePaginator';
import {Alert} from 'src/app/customer/models/alert.model';
import {SmsAlert} from 'src/app/customer/models/sms-alert.model';
import {BudTransaction} from 'src/app/rent-reporting/models/bud-transaction';
import {Note} from 'src/app/notes/models/note.model';

export class UpdateCustomer {
  static readonly type = '[Customer] Update Customer';

  constructor(public guid: string, public force: boolean = false) {
  }
}

export class UpdateNotes {
  static readonly type = '[Customer] Update Notes';

  constructor(public guid: string, public page: number = 0, public force: boolean = false) {
  }
}

/**
 * This is for updating a specific customer's alerts, found on the detail page.  For dashboard alerts, see SessionState
 */
export class UpdateAlerts {
  static readonly type = '[Customer] Update Alerts';

  constructor(public alerts: LengthAwarePaginator<Alert>) {
  }
}

export class UpdateSmsAlerts {
  static readonly type = '[Customer] Update SMS Alerts';

  constructor(public guid: string, public page: number = 0, public force: boolean = false) {
  }
}

export class ResolveSmsAlert {
  static readonly type = '[Customer] Resolve SMS alerts';

  constructor(public alertId: number) {
  }
}

export class UpdateRentInfo {
  static readonly type = '[Customer] Update rent info';

  constructor(public guid: string, public force: boolean = false) {
  }
}

export class UpdateBankTransactions {
  static readonly type = '[Customer] Update bank transactions';

  constructor(public guid: string, public page: number = 0, public force: boolean = false) {
  }
}

/**
 * Takes bank transactions and marks them as rent payments, queueing them for transmission to the credit agency.
 */
export class FlagRentTransactions {
  static readonly type = '[Customer] Flag rent transactions';

  constructor(public transactions: BudTransaction[]) {
  }
}

export class ApproveRentDeclarations {
  static readonly type = '[Customer] Approve rent declarations';

  constructor(public guid: string, public rentInfoId: number) {
  }
}

export class CreateNote {
  static readonly type = '[Customer] Create note';

  /**
   * @param note
   * @param isResolveNote boolean denoting if note is to resolve an alert
   */
  constructor(public note: Note, public isResolveNote: boolean = false) {
  }
}
