import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpErrorResponse} from '@angular/common/http';
import {Note} from '../models/note.model';
import {Alert} from '../../customer/models/alert.model';
import {User} from '../../shared/models/user.model';
import {HistoryService} from '../../shared/auth/services/history.service';
import {Router} from '@angular/router';
import {AlertService} from "@ratespecial/core";
import {ErrorService} from "../../shared/error/error.service";
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {CapitalizePipe} from 'src/app/shared/pipes/capitalize.pipe';
import {ErrorComponent} from 'src/app/shared/error/error.component';
import {Observable} from 'rxjs';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Store} from '@ngxs/store';
import {SessionState} from 'src/app/shared/state/session.state';
import * as CustomerActions from 'src/app/shared/state/customer.actions';


@Component({
  selector: 'app-add-note-modal',
  templateUrl: 'add-note-modal.component.html',
  styleUrls: ['add-note-modal.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    CapitalizePipe,
    ErrorComponent,
  ],
})
export class AddNoteModalComponent {

  // Add reference to Object.keys function to template
  public objectKeys = Object.keys;

  public note: Note;

  user$: Observable<User>;

  /**
   * If we are resolving an alert, this is the one
   */
  public alert: Alert | null = null;

  /**
   * Form validation errors
   */
  public errors: any;


  constructor(
    private alertService: AlertService,
    public activeModal: NgbActiveModal,
    public historyService: HistoryService,
    private router: Router,
    private errorService: ErrorService,
    private store: Store,
  ) {
    this.user$ = this.store.select(SessionState.getUser).pipe(takeUntilDestroyed());
  }


  save() {
    let isResolveNote = false;

    // When resolving alerts, we need these extra vars
    if (this.alert) {
      this.note.resolve_note_id = this.alert.note_id;
      this.note.alert_id = this.alert.id;
      isResolveNote = true;
    }

    this.store
      .dispatch(new CustomerActions.CreateNote(this.note, isResolveNote))
      .subscribe({
        next: () => {
          this.alertService.success('Customer Note Added');
          this.close();
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.handleValidationErrors(err);
        },
      });
  }


  close() {
    this.activeModal.close();

    // If a route is in history, go there
    const route = this.historyService.consumeLastVisitedRoute();
    if (route) {
      this.router.navigateByUrl(route);
    }
  }
}
