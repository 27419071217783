import {Routes} from '@angular/router';
import {AuthGuard} from './shared/auth/services/auth.guard';
import {DashboardComponent} from './dashboard/dashboard.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {ProspectListComponent} from './prospect/prospect-list/prospect-list.component';
import {ProspectDetailComponent} from './prospect/prospect-detail/prospect-detail.component';
import {LoginComponent} from './auth/login/login.component';
import {GoogleComponent} from './auth/social/google/google.component';
import {AddNoteGuard} from './customer/customer-detail/guards/AddNoteGuard';
import {LoginGuard} from './shared/auth/services/login.guard';
import {RecordingSearchComponent} from './call-recordings/recording-search/recording-search.component';
import {StorageAccessApiGuard} from 'src/app/shared/auth/services/storage-access-api.guard';
import {CookieAccessComponent} from 'src/app/auth/cookie-access/cookie-access.component';
import {CustomerDetailComponent} from 'src/app/customer/customer-detail/customer-detail.component';
import {CustomerSearchComponent} from 'src/app/customer/customer-search/customer-search.component';
import {AuthenticatedLayoutComponent} from 'src/app/layout/authenticated-layout/authenticated-layout.component';
import {RentPageComponent} from 'src/app/rent-reporting/rent-page/rent-page.component';
import {rentPageRentInfoResolver} from 'src/app/rent-reporting/rent-page/rent-page-rent-info.resolver';
import {customerDetailPageCustomerResolver} from 'src/app/customer/customer-detail/customer-detail-page-customer.resolver';

export const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: '/dashboard'},
  {
    path: 'auth',
    pathMatch: 'prefix',
    children: [
      {path: 'login', component: LoginComponent, canActivate: [StorageAccessApiGuard, LoginGuard]},
      {path: 'google', component: GoogleComponent, canActivate: [StorageAccessApiGuard, LoginGuard]},
      {path: 'cookie-access', component: CookieAccessComponent},
    ]
  },
  {
    path: '',
    pathMatch: 'prefix',
    canActivate: [StorageAccessApiGuard, AuthGuard],
    component: AuthenticatedLayoutComponent,
    children: [
      {path: 'dashboard', component: DashboardComponent},
      {
        path: 'customer',
        children: [
          {
            path: 'detail/:guid',
            pathMatch: 'full',
            component: CustomerDetailComponent,
            resolve: {customer: customerDetailPageCustomerResolver, rentInfo: rentPageRentInfoResolver},
            canDeactivate: [AddNoteGuard()]
          },
          {
            path: ':guid/rent-reporting',
            pathMatch: 'full',
            component: RentPageComponent,
            resolve: {rentInfo: rentPageRentInfoResolver}
          },
          {path: 'search', component: CustomerSearchComponent}
        ]
      },
      {
        path: 'prospect',
        children: [
          {path: 'detail/:id/:guid', component: ProspectDetailComponent},
          {path: 'list', component: ProspectListComponent}
        ]
      },
      {
        path: 'cancellation/list',
        loadComponent: () => import('./cancellations/cancellation-list/cancellation-list.component').then(m => m.CancellationListComponent)
      },
      {
        path: 'call-recordings',
        component: RecordingSearchComponent
      },
      {
        path: 'paid-trial',
        loadComponent: () => import('./paid-trial/paid-trial/paid-trial.component').then(m => m.PaidTrialComponent)
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin-routes').then(m => m.routes),
      },
      {
        path: 'whatsnew',
        loadComponent: () => import('./whatsnew/whatsnew.component').then(m => m.WhatsnewComponent)
      },
      {path: 'not-found', component: PageNotFoundComponent},
      {path: '**', component: PageNotFoundComponent}
    ]
  }
];
